import { createContext, useCallback, useState } from 'react';

import { CALCUTATION_TYPES, DISPLAY_OPTIONS_VALUES, STATUS_COLUMN_STATE } from '../constants';
import { BoardInfo } from '../services/monday/monday-api';
import { Column, StatusColumnValue } from '../types';
import { storage } from '../utils';
import { ExistingSelectedColumn, getUpdatedStatusesOrder, setOrderById, StatusesByColumn } from './services/funnel';

export type User = { id: number; name: string; photoThumbSmall: string };
export type DateFilter = { value: number; label: string };

export interface SettingsState {
  selectedColumn: Column | null;
  statusColumns: Column[];
  statusesByColumn: StatusesByColumn;
  users: User[];
  numberColumns: Column[];
  selectedUser: User | null;
  selectedDate: DateFilter | null;
  displayOption: string | null;
  selectedNumberColumn: Column | null;
  calculationType: string;
  isUpdating: boolean;
  boardsInfo: BoardInfo[];
  selectedBoardId: string | null;
  initSelectedBoard: boolean;
}

export interface ISettingsContext {
  settingsState: SettingsState;
  settingsInit: (settings: any) => void;
  setStatusColumn(id?: string): void;
  setNumberColumn(id?: string): void;
  getSettingsStatuses: () => StatusColumnValue[];
  setStatusSelected(id: string, selected: boolean): void;
  setStatusesOrder(orderedIds: string[]): void;
  setSelectedUser(id?: string): void;
  setSelectedDate(date?: DateFilter): void;
  setCalculationType(type: string): void;
  setDisplayOption(option?: string): void;
  setSettingsState: React.Dispatch<React.SetStateAction<SettingsState>>;
  setUpdatedSettingsState(updatedSettings: any, column: ExistingSelectedColumn): void;
  setSelectedBoard(id: string): void;
}

export const SettingsContext = createContext({} as ISettingsContext);

const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [settingsState, setSettingsState] = useState({
    selectedColumn: null,
    statusColumns: [],
    statusesByColumn: {},
    users: [],
    numberColumns: [],
    selectedUser: null,
    selectedDate: null,
    displayOption: DISPLAY_OPTIONS_VALUES.SELECTED_COLUMN_COLOR,
    selectedNumberColumn: null,
    calculationType: CALCUTATION_TYPES.ITEMS,
    isUpdating: false,
    boardsInfo: [],
    selectedBoardId: null,
    initSelectedBoard: false,
  } as SettingsState);

  const settingsInit = useCallback((settings: any) => {
    setSettingsState((state) => ({ ...state, ...settings }));
  }, []);

  function setStatusColumn(id?: string) {
    if (!id) {
      setSettingsState((state) => ({ ...state, selectedColumn: null }));
      return;
    }
    const column = settingsState.statusColumns.find((statusColumn) => statusColumn.id === id);
    if (!column) return;

    setSettingsState((state) => ({
      ...state,
      selectedColumn: column,
    }));
  }

  function setNumberColumn(id?: string) {
    if (!id) {
      setSettingsState((state) => ({ ...state, selectedNumberColumn: null, calculationType: CALCUTATION_TYPES.ITEMS }));
      return;
    }
    const column = settingsState.numberColumns.find((statusColumn) => statusColumn.id === id);

    setSettingsState((state) => ({
      ...state,
      selectedNumberColumn: column ? column : null,
      calculationType: column ? CALCUTATION_TYPES.SUM : CALCUTATION_TYPES.ITEMS,
    }));
  }

  function setCalculationType(type: string) {
    setSettingsState((state) => ({ ...state, calculationType: type ? type : CALCUTATION_TYPES.ITEMS }));
  }

  function getSettingsStatuses() {
    const id = settingsState.selectedColumn?.id;
    if (!id) return [];
    return settingsState.statusesByColumn[id] || [];
  }

  function setSelectedUser(id?: string) {
    if (!id) {
      setSettingsState((state) => ({ ...state, selectedUser: null }));
      return;
    }

    const user = settingsState.users.find((user) => user.id === Number(id));

    setSettingsState((state) => ({ ...state, selectedUser: user || null }));
  }

  function setStatusSelected(id: string, selected: boolean) {
    const columnId = settingsState.selectedColumn?.id;
    if (!columnId) return;

    const settingsStatuses = getSettingsStatuses();
    const updatedSettingsStatuses = settingsStatuses.map((status) => {
      if (status.id === id) {
        status.state = selected ? STATUS_COLUMN_STATE.SELECTED : STATUS_COLUMN_STATE.UNSELECTED;
        return status;
      }

      return status;
    });

    setSettingsState((state) => {
      return {
        ...state,
        statusesByColumn: {
          ...state.statusesByColumn,
          [columnId]: updatedSettingsStatuses,
        },
      };
    });
  }

  function setStatusesOrder(orderedIds: string[]) {
    const columnId = settingsState.selectedColumn?.id;
    if (!columnId) return;

    setSettingsState((state) => {
      return {
        ...state,
        statusesByColumn: {
          ...state.statusesByColumn,
          [columnId]: setOrderById(orderedIds, getSettingsStatuses()),
        },
      };
    });
  }

  function setSelectedDate(date?: DateFilter) {
    setSettingsState((state) => ({ ...state, selectedDate: date || null }));
  }

  function setDisplayOption(option?: string) {
    setSettingsState((state) => ({ ...state, displayOption: option || null }));
  }

  function setSelectedBoard(id: string) {
    if (id === settingsState.selectedBoardId) return;
    setSettingsState((state) => ({ ...state, selectedBoardId: id, initSelectedBoard: true }));
  }

  async function updatePrevioulsySelectedBoard(updatedSettings: any, column: any) {
    const savedSettings = await storage.getFromMonday(String(updatedSettings.boardId));
    const { id, exists } = column;
    const updatedNumberColumn =
      updatedSettings.numberColumns.find((column: Column) => column.id === savedSettings.selectedNumberColumn?.id) ||
      null;

    if (exists) {
      await storage.setToMonday(String(updatedSettings.boardId), {
        ...savedSettings,
        users: updatedSettings.users,
        statusColumns: updatedSettings.statusColumns,
        numberColumns: updatedSettings.numberColumns,
        selectedNumberColumn: updatedNumberColumn,
        calculationType: updatedNumberColumn ? savedSettings.calculationType : CALCUTATION_TYPES.ITEMS,
        selectedUser: updatedSettings.users.find((user: any) => user.id === savedSettings.selectedUser?.id) || null,
      });
    } else {
      await storage.setToMonday(String(updatedSettings.boardId), {
        ...savedSettings,
        selectedColumn: savedSettings.selectedColumn?.id === id ? null : savedSettings.selectedColumn,
        statusColumns: updatedSettings.statusColumns,
        statusesByColumn: updatedSettings.statusesByColumn,
        numberColumns: updatedSettings.numberColumns,
        selectedNumberColumn: updatedNumberColumn,
        calculationType: updatedNumberColumn ? savedSettings.calculationType : CALCUTATION_TYPES.ITEMS,
      });
    }
  }

  function setUpdatedSettingsState(updatedSettings: any, column: ExistingSelectedColumn) {
    const { id, exists } = column;

    setSettingsState((state) => {
      if (Number(state.selectedBoardId) !== updatedSettings.boardId) {
        updatePrevioulsySelectedBoard(updatedSettings, column);
        return state;
      }

      const updatedNumberColumn =
        updatedSettings.numberColumns.find((column: Column) => column.id === state.selectedNumberColumn?.id) || null;

      if (exists) {
        return {
          ...state,
          statusesByColumn: {
            ...state.statusesByColumn,
            [updatedSettings.selectedColumn.id]: getUpdatedStatusesOrder(updatedSettings, state.statusesByColumn),
          },
          users: updatedSettings.users,
          statusColumns: updatedSettings.statusColumns,
          numberColumns: updatedSettings.numberColumns,
          selectedNumberColumn: updatedNumberColumn,
          calculationType: updatedNumberColumn ? state.calculationType : CALCUTATION_TYPES.ITEMS,
          selectedUser: updatedSettings.users.find((user: any) => user.id === state.selectedUser?.id) || null,
        };
      } else {
        return {
          ...state,
          selectedColumn: state.selectedColumn?.id === id ? null : state.selectedColumn,
          statusColumns: updatedSettings.statusColumns,
          statusesByColumn: updatedSettings.statusesByColumn,
          numberColumns: updatedSettings.numberColumns,
          selectedNumberColumn: updatedNumberColumn,
          calculationType: updatedNumberColumn ? state.calculationType : CALCUTATION_TYPES.ITEMS,
        };
      }
    });
  }

  return (
    <SettingsContext.Provider
      value={{
        settingsState,
        settingsInit,
        getSettingsStatuses,
        setCalculationType,
        setDisplayOption,
        setNumberColumn,
        setSelectedDate,
        setSelectedUser,
        setStatusColumn,
        setStatusesOrder,
        setStatusSelected,
        setSettingsState,
        setUpdatedSettingsState,
        setSelectedBoard,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
