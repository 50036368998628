export enum MONDAY_CONSTANTS {
  PER_PAGE_LIMIT = 10_000,
  LOGS_COMPLEXITY = 30_020,
  ITEMS_COMPLEXITY = 150_400,
  ITEMS_COMPLEXITY_DEFAULT = 8020,
  MAX_ITEMS_ON_BOARD = 10_000,
  ITEMS_PER_REQUEST = 500,
  MAX_LOGS_PAGES = 2,
  UPDATE_INTERVAL = 120_000,
  MAX_LOGS_COMPLEXITY = 1_200_800,
  UPDATE_VALUE = 'update_column_value',
  SELECTED_BOARD_KEY = 'selected_board',
}
