import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';

import { ReactComponent as ClearIcon } from '../../assets/clear_icon.svg';
import { ReactComponent as DropdownIcon } from '../../assets/dropdown_icon.svg';
import { DropdownOption } from '../../types';
import { isOverflowActive } from '../../utils';
import { WidgetTooltip } from '../WidgetTooltip/WidgetTooltip';

import './Dropdown.scss';

const styles = {
  control: (css: any, state: any) => ({
    ...css,
    backgroundColor: 'var(--primary-background-color)',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    minHeight: '30px',
    border: '0',
  }),
  valueContainer: () => ({ height: '100%', display: 'flex', flex: '1' }),
  singleValue: (_css: any, state: any) => ({ height: '100%', opacity: state.selectProps.menuIsOpen ? '0.6' : '1' }),
  indicatorsContainer: () => ({ height: '100%' }),
  input: (css: any, state: any) => ({ ...css, margin: '2px', cursor: state.isDisabled ? 'not-allowed' : 'pointer' }),
  menu: () => ({ width: '100%', zIndex: 15 }),
  option: () => ({ padding: '8px' }),
  dropdownIndicator: () => ({ padding: '0px' }),
  clearIndicator: (css: any) => ({ ...css, height: '24px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  container: (_css: any, state: any) => ({
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': { border: !state.isFocused ? '1px solid var(--primary-text-color)' : '1px solid #2684ff' },
  }),
  placeholder: (_css: any) => ({ width: '100%' }),
};

export const CustomDropdown: React.FC<{
  value: any;
  options: any;
  onChange: (option: DropdownOption) => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  className?: string;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}> = ({ value, options, onChange, isDisabled, isClearable, placeholder, className, onMenuOpen, onMenuClose }) => {
  return (
    <Select
      styles={styles}
      components={{
        Control,
        ValueContainer,
        SingleValue,
        IndicatorsContainer,
        Input,
        Menu,
        Option,
        DropdownIndicator,
        SelectContainer,
        ClearIndicator,
        Placeholder,
      }}
      value={value}
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      placeholder={placeholder}
      className={className}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

const ValueContainer = ({ children, ...props }: any) => {
  return (
    <components.ValueContainer {...props} className={cn('value-container', props.className)}>
      {children}
    </components.ValueContainer>
  );
};

const SingleValue = ({ children, ...props }: any) => {
  const value = props.getValue()[0];
  const icon = value?.icon;
  const spanRef = useRef<HTMLSpanElement>(null);
  const [tooltip, setTooltip] = useState({} as { enable: boolean; value: string });
  const label = props.options.find((option: any) => option.label === value.label)?.intlLabel || value.label;

  useEffect(() => {
    if (spanRef.current) {
      setTooltip({ enable: true, value: value });
    }
  }, [spanRef, value]);

  return (
    <components.SingleValue {...props} className={cn('single-value', props.className)}>
      {icon && label && icon}
      <WidgetTooltip content={label} enable={tooltip?.enable && isOverflowActive(spanRef.current)}>
        <span ref={spanRef} style={{ marginLeft: icon ? '28px' : 0 }} className="single-value__label">
          {label}
        </span>
      </WidgetTooltip>
    </components.SingleValue>
  );
};

const Control = ({ children, ...props }: any) => {
  return (
    <components.Control {...props} className={cn('control', props.className)}>
      {children}
    </components.Control>
  );
};

const IndicatorsContainer = ({ children, ...props }: any) => {
  return (
    <components.IndicatorsContainer {...props} className={cn('indicator', props.className)}>
      {children}
    </components.IndicatorsContainer>
  );
};

const Input = ({ children, ...props }: any) => {
  return (
    <components.Input {...props} className={cn('input', props.className)}>
      {children}
    </components.Input>
  );
};

const Menu = ({ children, ...props }: any) => {
  return (
    <components.Menu {...props} className={cn('menu', props.className)}>
      {children}
    </components.Menu>
  );
};

const Option = ({ children, ...props }: any) => {
  const icon = props.data?.icon;
  const spanRef = useRef<HTMLSpanElement>(null);
  const [tooltip, setTooltip] = useState(false);
  const isSelected = props.data?.label === props.getValue()[0]?.label;
  const label = props.data?.intlLabel ? props.data.intlLabel : props.data.label;

  useEffect(() => {
    if (spanRef.current && isOverflowActive(spanRef.current)) {
      setTooltip(true);
    }
  }, [spanRef]);

  return (
    <components.Option {...props} className={cn('option', props.className, { selectedOption: isSelected })}>
      {icon && icon}
      <WidgetTooltip content={label} enable={tooltip}>
        <span ref={spanRef} style={{ marginLeft: icon ? '28px' : 0 }}>
          {label}
        </span>
      </WidgetTooltip>
    </components.Option>
  );
};

const DropdownIndicator = ({ children, ...props }: any) => {
  return (
    <components.DropdownIndicator {...props} className={cn('dropdown-indicator', props.className)}>
      <DropdownIcon
        className="dropdown-indicator__icon"
        style={{ transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
      />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = ({ children, ...props }: any) => {
  return (
    <components.DropdownIndicator {...props} className={cn('clear-indicator', props.className)}>
      <ClearIcon />
    </components.DropdownIndicator>
  );
};

const SelectContainer = ({ children, ...props }: any) => {
  return (
    <components.SelectContainer {...props} className={cn('container', props.className)}>
      {children}
    </components.SelectContainer>
  );
};

const Placeholder = ({ children, ...props }: any) => {
  return (
    <components.Placeholder {...props} className={cn('placeholder', props.className)}>
      {children}
    </components.Placeholder>
  );
};
