import { Tooltip } from 'monday-ui-react-core';

export function WidgetTooltip({ children, ...props }: TooltipProps) {
  if (props.enable) {
    return <Tooltip {...props}>{children}</Tooltip>;
  }
  return children;
}

export interface TooltipProps {
  children: React.ReactElement;
  content: string;
  enable?: boolean;
}
