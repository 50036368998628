import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FRAMES_CONSTANTS, UI_MODE } from '../constants';
import { getMode } from '../services/config.service';
import { monday, openModal } from '../services/monday/monday-api';
import { MONDAY_CONSTANTS } from '../services/monday/monday-constants';
import { getColumnData, isEnoughComplexityForInitUpdate } from '../services/monday/monday-service';
import { FatalError, findMainApp, findSettingsApp, setFrameProperty, storage } from '../utils';
import { FunnelState } from './FunnelContext';
import { useFunnel, useSettings } from './hooks';
import { ExistingSelectedColumn, getInitializedFunnel, updateFunnelInfo } from './services/funnel';
import { SettingsState } from './SettingsContext';

export interface IUiContext {
  uiState: string;
  isSettingsMode: boolean;
  areSettingsEmpty: boolean;
  isNormalMode: boolean;
  isErrorMode: boolean;
  isLoading: boolean;
  errorMessage: string | null;
  isEmptyFilterState: boolean;
  openSettings(): Promise<void>;
  setErrorMode(error: FatalError): void;
}

export const UiContext = createContext({} as IUiContext);

const UiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [uiState, setUiState] = useState<string>(getMode());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isUpdatedOnInit, setIsUpdatedOninit] = useState<boolean>(false);
  const timeOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    funnelState,
    statusConversion,
    syncModesStates,
    setSelectedColumnData,
    setUpdatedFunnelState,
    setFunnelState,
    mondayStorageSettings,
  } = useFunnel();
  const { settingsState, setUpdatedSettingsState, setSettingsState } = useSettings();

  const isSettingsMode = useMemo(() => {
    return uiState === UI_MODE.SETTINGS;
  }, [uiState]);

  const isNormalMode = useMemo(() => {
    return uiState === UI_MODE.NORMAL;
  }, [uiState]);

  const isErrorMode = useMemo(() => {
    return uiState === UI_MODE.ERROR;
  }, [uiState]);

  const isLoading = useMemo(() => {
    return !funnelState.initialized || funnelState.isReloading || settingsState.initSelectedBoard;
  }, [funnelState.initialized, funnelState.isReloading, settingsState.initSelectedBoard]);

  function setErrorMode(error: FatalError) {
    setUiState(UI_MODE.ERROR);

    if (error.isFatal) {
      setErrorMessage(error.message);
    }
  }

  const areSettingsEmpty = useMemo(() => {
    return funnelState.initialized && statusConversion.conversion.length < 1;
  }, [funnelState.initialized, statusConversion.conversion.length]);

  const isEmptyFilterState = useMemo(() => {
    return funnelState.initialized && statusConversion.conversion.every((status) => status.count === 0);
  }, [funnelState.initialized, statusConversion.conversion]);

  async function openSettings() {
    if (isSettingsMode) return;
    await openModal({
      urlParams: { mode: UI_MODE.SETTINGS },
      width: 'calc(100vw - 64px)',
      height: 'calc(100vh - 64px)',
    });
  }

  useEffect(() => {
    if (isNormalMode) {
      const id = uuidv4();

      setFrameProperty(FRAMES_CONSTANTS.MODE, FRAMES_CONSTANTS.NORMAL);
      setFrameProperty(FRAMES_CONSTANTS.FRAME_ID, id);
      storage.setToMonday(FRAMES_CONSTANTS.FRAME_ID, id);
    }
    if (isSettingsMode) {
      setFrameProperty(FRAMES_CONSTANTS.MODE, FRAMES_CONSTANTS.SETTINGS);
    }
  }, [isNormalMode, isSettingsMode]);

  useEffect(() => {
    if (!funnelState.initialized || !isNormalMode || isUpdatedOnInit || settingsState.isUpdating) return;
    (async () => {
      const { isEnough, resetInSeconds } = await isEnoughComplexityForInitUpdate();

      if (isEnough) {
        updateFunnelState().then(() => {
          setIsUpdatedOninit(true);
        });

        return;
      }

      timeOutRef.current = setTimeout(() => {
        updateFunnelState().then(() => {
          setIsUpdatedOninit(true);
        });
      }, resetInSeconds * 1000 + 500);
    })();
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
        timeOutRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    funnelState.initialized,
    isNormalMode,
    settingsState.selectedColumn?.id,
    isUpdatedOnInit,
    settingsState.isUpdating,
    settingsState.selectedBoardId,
  ]);

  useEffect(() => {
    if (!isNormalMode || !funnelState.initialized) return;
    const listener = (e: MessageEvent) => {
      if (e.origin !== window.origin) return;
      if (e.data?.stateSync) {
        syncModesStates(e.data.state);
      }
    };
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [isNormalMode, funnelState.initialized, syncModesStates]);

  useEffect(() => {
    if (!isNormalMode || !funnelState.initialized) return;

    const listener = (event: MessageEvent) => {
      if (event.origin !== window.origin) return;

      if (event.data === 'SEND_STATE') {
        const settings = findSettingsApp();
        settings?.postMessage(
          {
            isState: true,
            state: {
              funnel: funnelState,
              settings: settingsState,
            },
          },
          settings.origin
        );
      }
    };
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [isNormalMode, funnelState, settingsState]);

  useEffect(() => {
    if (!isSettingsMode || !funnelState.initialized) return;

    const mainApp = findMainApp();
    mainApp?.postMessage(
      {
        state: {
          funnel: funnelState,
          settings: settingsState,
        },
        stateSync: true,
      },
      mainApp.origin
    );

    const listener = (event: MessageEvent) => {
      if (event.origin !== window.origin) return;

      if (event.data?.columnState) {
        setSelectedColumnData(event.data.state);
      }

      if (event.data?.isError) {
        setUiState(UI_MODE.ERROR);
        setErrorMessage(event.data.message);
      }

      if (event.data?.isUpdatedFunnel) {
        setUpdatedFunnelState(event.data.state.funnel, event.data.column);
        setUpdatedSettingsState(event.data.state.settings, event.data.column);
        setSettingsState((state) => ({ ...state, isUpdating: false }));
      }

      if (event.data?.newSelectedBoard) {
        setFunnelState((state) => ({ ...state, ...event.data.state.funnel }));
        setSettingsState({ ...event.data.state.settings, initSelectedBoard: false });
      }
    };
    window.frames.addEventListener('message', listener);

    return () => {
      window.frames.removeEventListener('message', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSettingsMode, funnelState, settingsState]);

  useEffect(() => {
    if (!isErrorMode) return;

    const settings = findSettingsApp();
    settings?.postMessage(
      {
        isError: true,
        message: errorMessage,
      },
      settings.origin
    );
  }, [isErrorMode, errorMessage]);

  async function fetchSelectedColumnInfo() {
    try {
      const { itemStatusInfo, statusesByColumn } = await getColumnData(
        funnelState.boardId!,
        settingsState.selectedColumn!,
        funnelState.personColumns,
        settingsState.statusesByColumn,
        settingsState.numberColumns
      );

      await storage.setBoardCache(funnelState.boardId!, itemStatusInfo);

      const settingsApp = findSettingsApp();

      if (!settingsApp) {
        setSelectedColumnData({
          statusesByColumn,
          itemStatusInfo,
        });
        return;
      }

      settingsApp?.postMessage(
        {
          columnState: true,
          state: {
            statusesByColumn,
            itemStatusInfo,
          },
        },
        settingsApp.origin
      );
    } catch (error: any) {
      console.error(error);
      setErrorMode(error);
    }
  }

  useEffect(() => {
    if (!settingsState.selectedColumn?.id || !funnelState.isReloading || !isNormalMode) return;

    (async () => {
      await fetchSelectedColumnInfo();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelState.isReloading, settingsState.selectedColumn?.id, isNormalMode]);

  async function updateFunnelState() {
    setSettingsState((state) => ({ ...state, isUpdating: true }));

    try {
      const [updatedFunnel, selectedColumn] = (await updateFunnelInfo(
        funnelState.boardId!,
        settingsState.selectedColumn!,
        settingsState.statusesByColumn,
        funnelState.itemStatusInfo,
        settingsState.selectedNumberColumn!
      )) as [{ funnel: FunnelState; settings: SettingsState }, ExistingSelectedColumn];

      const settingsApp = findSettingsApp();
      if (settingsApp) {
        settingsApp?.postMessage(
          {
            isUpdatedFunnel: true,
            state: updatedFunnel,
            column: selectedColumn,
          },
          settingsApp.origin
        );
      } else {
        setUpdatedFunnelState(updatedFunnel.funnel, selectedColumn);
        setUpdatedSettingsState(updatedFunnel.settings, selectedColumn);
        setSettingsState((state) => ({ ...state, isUpdating: false }));
      }
    } catch (error: any) {
      console.error(error);
      setErrorMode(error);
    }
  }

  useEffect(() => {
    if (
      !isNormalMode ||
      !funnelState.initialized ||
      !settingsState.selectedColumn?.id ||
      !isUpdatedOnInit ||
      settingsState.initSelectedBoard
    )
      return;

    const intervalId = setInterval(updateFunnelState, MONDAY_CONSTANTS.UPDATE_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isNormalMode,
    settingsState.selectedColumn?.id,
    funnelState.initialized,
    isUpdatedOnInit,
    funnelState.boardId,
    settingsState.initSelectedBoard,
  ]);

  useEffect(() => {
    if (!isNormalMode || !funnelState.initialized || !settingsState.selectedBoardId) return;

    storage.setToMonday(String(funnelState.boardId), mondayStorageSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelState.boardId, funnelState.initialized, isNormalMode, mondayStorageSettings]);

  useEffect(() => {
    if (!settingsState.initSelectedBoard || !isNormalMode) return;

    (async () => {
      try {
        await storage.setToMonday(MONDAY_CONSTANTS.SELECTED_BOARD_KEY, { id: settingsState.selectedBoardId });
        const { funnel, settings } = (await getInitializedFunnel(Number(settingsState.selectedBoardId))) as {
          funnel: FunnelState;
          settings: SettingsState;
        };
        const settingsApp = findSettingsApp();
        if (settingsApp) {
          settingsApp?.postMessage(
            {
              newSelectedBoard: true,
              state: {
                funnel: funnel,
                settings: settings,
              },
            },
            settingsApp.origin
          );
        } else {
          setFunnelState((state) => ({ ...state, ...funnel }));
          setSettingsState({ ...settings, initSelectedBoard: false });
        }
      } catch (error: any) {
        console.error(error);
        setErrorMode(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState.selectedBoardId, settingsState.initSelectedBoard, isNormalMode]);

  useEffect(() => {
    if (!isSettingsMode) return;

    function closeSettings(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        monday.execute('closeAppFeatureModal');
      }
    }

    window.addEventListener('keydown', closeSettings);
    return () => {
      window.removeEventListener('keydown', closeSettings);
    };
  }, [isSettingsMode]);

  return (
    <UiContext.Provider
      value={{
        uiState,
        isSettingsMode,
        isNormalMode,
        isErrorMode,
        isLoading,
        areSettingsEmpty,
        errorMessage,
        isEmptyFilterState,
        openSettings,
        setErrorMode,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export default UiProvider;
