import cn from 'classnames';
import { t } from 'i18next';
import { Avatar, Button, Dropdown, IconButton, Tooltip } from 'monday-ui-react-core';
import { Settings } from 'monday-ui-react-core/dist/allIcons';
import React, { useMemo } from 'react';

import { useFunnel, useNavigation, useSettings } from '../../contexts/hooks';
import { DateFilter, User } from '../../contexts/SettingsContext';
import { date } from '../../utils';
import { Chart } from '../Chart/Chart';
import { CustomDropdown } from '../Dropdown/Dropdown';
import { EmptyState } from '../EmptyState/EmptyState';
import { OptionRenderer } from '../OptionsRenderer/OptionsRenderer';

import './FunnelView.scss';

const DATE_OPTIONS = {
  Yesterday: 'dropdown.date_option_yesterday',
  'Last 7 days': 'dropdown.date_option_last-7-days',
  'Last 14 days': 'dropdown.date_option_last-14-days',
  'Last 30 days': 'dropdown.date_option_last-30-days',
  'Last 3 months': 'dropdown.date_option_last-3-months',
  'Last 6 months': 'dropdown.date_option_last-6-months',
  'This Month': 'dropdown.date_option_this-month',
  'This Quarter': 'dropdown.date_option_this-quarter',
  'Last 2 Quarters': 'dropdown.date_option_last-2-quarters',
};

export const FunnelView: React.FC = () => {
  const { isNormalMode, openSettings, isSettingsMode, isEmptyFilterState } = useNavigation();
  const { statusConversion, funnelState } = useFunnel();
  const { settingsState, setSelectedUser, setSelectedDate } = useSettings();
  const { conversion, conversionPercent } = statusConversion;

  const personPlaceholder: string =
    funnelState.personColumns.length === 1 ? funnelState.personColumns[0].title : t('funnel.all_people');
  const dateOptions = useMemo(() => {
    return date.getDateOptions(funnelState.timeZoneOffset).map((option) => ({
      ...option,
      intlLabel: t<string>(DATE_OPTIONS[option.label as keyof typeof DATE_OPTIONS]),
    }));
  }, [funnelState.timeZoneOffset]);

  function handleSettingsClick() {
    openSettings();
  }

  const selectedUser = userToOption(settingsState.selectedUser);
  const users = settingsState.users.map(userToOption);
  const selectedDate = dateToOption(settingsState.selectedDate);

  function handleUserFilterChange(option: any) {
    setSelectedUser(option?.value);
  }

  function handleDateFilterChange(option: any) {
    setSelectedDate(option);
  }

  return (
    <div className={cn('funnel-view', { 'settings-mode-height': isSettingsMode })}>
      <div className="funnel-view_filter-outer">
        {isNormalMode && (
          <div className="funnel-view_filter-wrapper">
            <Dropdown
              onChange={handleUserFilterChange}
              options={users}
              value={selectedUser}
              placeholder={personPlaceholder}
              className={cn('person-dropdown', { 'dropdown-scroll': users.length > 4 })}
              optionRenderer={OptionRenderer}
              size={Dropdown.size.SMALL}
              maxMenuHeight={200}
            />
            <div className="point" />
            <CustomDropdown
              onChange={handleDateFilterChange}
              options={dateOptions}
              value={selectedDate}
              className="date-dropdown"
              placeholder={t('funnel.start_date')}
              isClearable={true}
            />
            <Tooltip content={t('settings.funnel_settings')}>
              <IconButton
                wrapperClassName="settings-button"
                icon={Settings}
                size={Button.sizes.SMALL}
                onClick={handleSettingsClick}
              />
            </Tooltip>
          </div>
        )}
        <div className={cn('label-wrapper', { 'settings-mode-label': isSettingsMode })}>
          <Tooltip content={t('beta_mode')}>
            <div className="funnel-view_label">Beta</div>
          </Tooltip>
        </div>
      </div>

      {!isEmptyFilterState ? (
        <div className="funnel-view_chart-outer">
          <Chart />
          <div className="conversion-wrapper">
            <div className="arrow">
              <div className="triangle"></div>
              <div className="row"></div>
            </div>
            <div className="conversion-wrapper_content">
              <div className="conversion-wrapper_content-count">{conversionPercent} %</div>
              <div className="conversion-wrapper_content-text primary-text-color">
                {t<string>('funnel.conversion_to') + ' ' + conversion[conversion.length - 1].label}
              </div>
            </div>
            <div className="arrow">
              <div className="row"></div>
              <div className="triangle triangle-bottom"></div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

function userToOption(user: User | null) {
  if (!user) return null;

  return {
    value: String(user.id),
    label: user.name,
    src: user.photoThumbSmall,
    type: Avatar.types.IMG,
    size: Avatar.sizes.SMALL,
  };
}

function dateToOption(date: DateFilter | null) {
  if (!date) return null;

  return {
    value: date.value,
    label: date.label,
  };
}
