import cn from 'classnames';
import { t } from 'i18next';
import { Divider, Heading } from 'monday-ui-react-core';
import { Loader } from 'monday-ui-react-core';
import { useEffect } from 'react';

import { EmptyConfigMessage } from './components/EmptyConfigMessage/EmptyConfigMessage';
import { ErrorToast } from './components/ErrorToast/ErrorToast';
import { FunnelView } from './components/FunnelView/FunnelView';
import { Settings } from './components/Settings/Settings';
import { useFunnel, useNavigation } from './contexts/hooks';
import { monday } from './services/monday/monday-api';
import { setTheme } from './utils/theme';

import 'monday-ui-react-core/dist/main.css';
import './App.scss';

function App() {
  const { funnelInit, setItemsIds } = useFunnel();
  const { isSettingsMode, areSettingsEmpty, isErrorMode, setErrorMode, errorMessage, isLoading } = useNavigation();

  useEffect(() => {
    monday.listen('context', (res: { data: any }) => {
      const { theme } = res.data;
      setTheme(theme);
    });
  });

  useEffect(() => {
    (async function () {
      try {
        await funnelInit();
      } catch (error: any) {
        console.error(error);
        setErrorMode(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    monday.listen('itemIds', (res: { data: any }) => {
      const { data } = res as { data: number[] };
      setItemsIds(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isErrorMode) {
    return <ErrorToast errorMessage={errorMessage} />;
  }

  if (isLoading) {
    return (
      <div className="main-app_loader">
        <Loader size={Loader.sizes.LARGE} />
      </div>
    );
  }

  return (
    <div className="main-app">
      {isSettingsMode && (
        <div className="main-app-header">
          <Heading
            className="main-app-header__title primary-text-color"
            type={Heading.types.h1}
            value={t<string>('settings.funnel_settings')}
            brandFont
          />
          <Divider className="main-app-header__divider" />
        </div>
      )}
      <div className={cn('main-app-container', { 'settings-mode': isSettingsMode })}>
        <>
          {areSettingsEmpty ? <EmptyConfigMessage /> : <FunnelView />}
          {isSettingsMode && (
            <>
              <Divider direction={Divider.directions.VERTICAL} className="settings-divider" />
              <Settings />
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default App;
