import { DateTime } from 'luxon';

export function lastSixMothsToISO() {
  const nowDate = new Date();
  nowDate.setMonth(nowDate.getMonth() - 6);

  return nowDate.toISOString();
}

export default class DateOptionsCreator {
  private dateNow = DateTime.now();

  getDateOptions(tz: number) {
    this.setTimeZone(tz);

    return [
      { label: 'Yesterday', value: this.getDayTime(1) },
      { label: 'Last 7 days', value: this.getDayTime(7) },
      { label: 'Last 14 days', value: this.getDayTime(14) },
      { label: 'Last 30 days', value: this.getDayTime(30) },
      { label: 'This Month', value: this.getThisMonthDate() },
      { label: 'Last 3 months', value: this.getMonthsTime(3) },
      { label: 'This Quarter', value: this.getQuarter(0) },
      { label: 'Last 6 months', value: this.getMonthsTime(6) },
      { label: 'Last 2 Quarters', value: this.getQuarter(1) },
    ];
  }

  private setTimeZone(tz: number) {
    const tzFormat = tz >= 0 ? `+${tz}` : tz;

    this.dateNow = this.dateNow.setZone(`UTC${tzFormat}`);
  }

  private getThisMonthDate() {
    return this.dateNow.startOf('month').toMillis();
  }

  private getDayTime(days: number) {
    return this.dateNow.minus({ days }).startOf('day').toMillis();
  }

  private getMonthsTime(month: number) {
    return this.dateNow.minus({ month }).startOf('day').toMillis();
  }

  private getQuarter(quarter: number) {
    return this.dateNow.minus({ quarter }).startOf('quarter').toMillis();
  }
}

export const date = new DateOptionsCreator();
