export enum STATUS_COLUMN_STATE {
  SELECTED = 'selected',
  UNSELECTED = 'unselected',
}

export enum CALCUTATION_TYPES {
  SUM = 'sum',
  AVERAGE = 'average',
  MEDIAN = 'median',
  ITEMS = 'items',
}
export const COLUMN_TYPE_STATUS = 'color';
export const COLUMN_TYPE_PERSON = 'multiple-person';
export const COLUMN_TYPE_NUMBER = 'numeric';
