import { Divider } from 'monday-ui-react-core';
import React from 'react';

import { DISPLAY_OPTIONS_VALUES } from '../../constants';
import { useFunnel, useSettings } from '../../contexts/hooks';
import { StatusConversion } from '../../contexts/services/conversion';
import { formatNumeric, roundNumber } from '../../utils';
import { ChartBar } from '../ChartBar/ChartBar';

import './Chart.scss';

const COLORS = {
  SINGLE_COLOR: 'var(--color-done-green)',
  FINAL_STAGE: 'var(--color-bright-blue)',
  DEFAULT_COLOR: 'var(--color-explosive)',
};

export const Chart: React.FC = () => {
  const { statusConversion } = useFunnel();
  const { settingsState } = useSettings();

  const { conversion } = statusConversion;

  const maxCount = [...conversion].sort((a, b) => b.count - a.count)[0].count;
  const averageCount = Math.round(maxCount / 2);
  const numberColumnSettings = settingsState.selectedNumberColumn?.settingsStr;

  function getPercentFromBigger(arr: StatusConversion[], index: number) {
    return roundNumber((arr[index].count * 100) / maxCount || 0);
  }

  function getChartBarBackgroundColor(labelId: string, lastInOrder: boolean): string {
    switch (settingsState.displayOption) {
      case DISPLAY_OPTIONS_VALUES.SELECTED_COLUMN_COLOR:
        return (
          JSON.parse(settingsState.selectedColumn!.settingsStr).labels_colors?.[labelId]?.color || COLORS.DEFAULT_COLOR
        );
      case DISPLAY_OPTIONS_VALUES.SINGLE_COLOR:
        return COLORS.SINGLE_COLOR;
      case DISPLAY_OPTIONS_VALUES.FINAL_STAGE_COLOR:
        return lastInOrder ? COLORS.SINGLE_COLOR : COLORS.FINAL_STAGE;
      default:
        return COLORS.SINGLE_COLOR;
    }
  }

  return (
    <div className="chart">
      <div className="chart_count-wrapper primary-text-color">
        <div className="chart_count">{formatNumeric(maxCount, numberColumnSettings)}</div>
        <div className="chart_count">{formatNumeric(averageCount, numberColumnSettings)}</div>
        <div className="chart_count"> {formatNumeric(0, numberColumnSettings)}</div>
      </div>
      <div className="chart_wrapper">
        <div className="chart_divider-wrapper top">
          <Divider className="divider" direction={Divider.directions.HORIZONTAL} />
        </div>
        <div className="chart_divider-wrapper middle">
          <Divider className="divider" direction={Divider.directions.HORIZONTAL} />
        </div>
        <div className="chart_divider-wrapper">
          <Divider className="divider" direction={Divider.directions.HORIZONTAL} />
        </div>

        {conversion.map((status, idx, arr) => {
          const isLast = idx === arr.length - 1;
          const startPoint = 100 - getPercentFromBigger(arr, idx);
          const endPoint = !isLast ? 100 - getPercentFromBigger(arr, idx + 1) : 100 - getPercentFromBigger(arr, idx);

          return (
            <ChartBar
              percentFromFirst={getPercentFromBigger(arr, idx)}
              count={formatNumeric(status.count, numberColumnSettings)}
              label={status.label}
              isLast={isLast}
              nextItemPercent={arr[idx + 1]?.percent}
              startPoint={startPoint}
              endPoint={endPoint}
              key={idx}
              bgColor={getChartBarBackgroundColor(status.id, isLast)}
              statusCount={conversion.length}
              columnLabel={settingsState.selectedColumn?.title}
              tooltipCount={formatNumeric(status.count, numberColumnSettings, true)}
            />
          );
        })}
      </div>
    </div>
  );
};
