import {
  boardsPriorities,
  COLUMN_TYPE_NUMBER,
  COLUMN_TYPE_PERSON,
  COLUMN_TYPE_STATUS,
  statusColumnsPriorities,
} from '../../constants';
import { loadColumnData, StatusesByColumn } from '../../contexts/services/funnel';
import { Column, ColumnValue, ItemValue } from '../../types';
import { getLabelId } from '../../utils';
import {
  ActivityLog,
  BoardInfo,
  checkEnoughComplexity,
  getActivityLog,
  getComplexity,
  getItemsCount,
  getItemsValue,
} from './monday-api';
import { MONDAY_CONSTANTS } from './monday-constants';

export async function getColumnAllItemsInfo(boardId: number, column: Column, itemsValues: ItemValue[][]) {
  const complexityForLogs = getComplexityForLogs(itemsValues.flat().length);
  await checkEnoughComplexity(complexityForLogs);
  return (await Promise.all(itemsValues.map((itemValue) => getColumnItemsInfo(boardId, column, itemValue)))).flat();
}

function getComplexityForLogs(itemsCount: number) {
  return Math.ceil(
    (itemsCount / MONDAY_CONSTANTS.ITEMS_PER_REQUEST) *
      (MONDAY_CONSTANTS.LOGS_COMPLEXITY * MONDAY_CONSTANTS.MAX_LOGS_PAGES)
  );
}

async function getColumnItemsInfo(boardId: number, column: Column, itemsValue: ItemValue[]): Promise<ItemInfo[]> {
  const itemsInfo = getItemsInfo(itemsValue, column);
  const itemsIds = [...itemsInfo.keys()];
  let result = { toDate: new Date().toISOString() } as { data: ActivityLog[]; toDate?: string };
  let page = 1;

  while (result.toDate && page <= MONDAY_CONSTANTS.MAX_LOGS_PAGES) {
    result = await getColumnActivityLog(boardId, column.id, itemsIds, result?.toDate);
    if (!result.toDate) break;

    updateColumnItemsInfo(result.data, itemsInfo);
    page++;
  }

  return [...itemsInfo.values()];
}

async function getColumnActivityLog(boardId: number, columnId: string, itemsIds: number[], toDate?: string) {
  const logs = await getActivityLog(boardId, columnId, itemsIds, toDate);
  const last = logs.length > 0 ? logs[logs.length - 1] : undefined;

  if (!last) {
    return {
      data: [],
      toDate: undefined,
    };
  }

  const isExtraTimeNeeded = logs.length === MONDAY_CONSTANTS.PER_PAGE_LIMIT;

  return {
    data: logs,
    toDate: getLastLogTimestamp(last, isExtraTimeNeeded),
  };
}

function getLastLogTimestamp(lastLog: ActivityLog, isExtraTimeNeeded: boolean) {
  const timestamp = Math.ceil(Number(lastLog.createdAt) / 10000);
  const date = new Date(timestamp);

  if (isExtraTimeNeeded) {
    date.setSeconds(date.getSeconds() + 1);
  }

  return date.toISOString();
}

function updateColumnItemsInfo(logs: ActivityLog[], existingInfo: ColumnItemsInfo) {
  for (const log of logs) {
    if (log.event !== MONDAY_CONSTANTS.UPDATE_VALUE) continue;
    const parseData = JSON.parse(log.data);

    const itemOnBoard = existingInfo.get(parseData.pulse_id);
    if (!itemOnBoard) continue;

    if (!parseData.value?.label) {
      console.log(log);
      continue;
    }

    existingInfo.set(parseData.pulse_id, {
      ...itemOnBoard,
      logs: [
        ...itemOnBoard.logs,
        {
          itemId: parseData.pulse_id,
          statusId: parseData.value.label.index,
          createdAt: String(Math.round(Number(log.createdAt) / 10000)),
          previousValue: parseData.previous_value
            ? { statusId: parseData.previous_value.label.index, varName: parseData.previous_value.label.style.var_name }
            : null,
        },
      ],
    });
  }
}

function getNumbersValues(numberColumnsValues: ColumnValue[]) {
  return numberColumnsValues.reduce((acc: any, colValue) => {
    const parsedValue = colValue?.value ? Number(JSON.parse(colValue.value)) : 0;
    acc[colValue.id] = parsedValue;
    return acc;
  }, {});
}

function getItemsInfo(itemsValue: ItemValue[], column: Column): ColumnItemsInfo {
  return itemsValue.reduce((curr, item) => {
    const { columnValues } = item;
    const statusColumnValue = columnValues.find((column) => column.type === COLUMN_TYPE_STATUS);
    const numberColumnsValues = columnValues.filter((column) => column.type === COLUMN_TYPE_NUMBER);
    const parsedValue = statusColumnValue ? JSON.parse(statusColumnValue.value) : undefined;

    curr.set(Number(item.id), {
      itemId: item.id,
      statusId: getLabelId(column.settingsStr, parsedValue?.index),
      statusLabel: statusColumnValue!.text,
      userIds: getPersonColumnUsers(columnValues),
      logs: [],
      createdDate: new Date(item.createdAt).getTime(),
      numberesById: getNumbersValues(numberColumnsValues),
    });
    return curr;
  }, new Map());
}

function getPersonColumnUsers(columnValues: ColumnValue[]): number[] {
  return columnValues
    .filter((columnValue) => columnValue.type === COLUMN_TYPE_PERSON)
    .map((personColumn) => {
      const parsedUserValue = JSON.parse(personColumn.value);
      if (!parsedUserValue) return [];
      return parsedUserValue?.personsAndTeams.map((user: { id: number }) => user.id);
    })
    .flat();
}

export async function getColumnData(
  boardId: number,
  column: Column,
  personColumns: Column[],
  statusesByColumn: StatusesByColumn,
  numberColumns: Column[]
) {
  const itemsValues = (
    await getAllItemsValue(boardId, [
      column.id,
      ...personColumns.map((column) => column.id),
      ...numberColumns.map((column) => column.id),
    ])
  ).filter((x) => x.length);

  const { itemStatusInfo, statusesByColumn: updatedStatusesByColumn } = await loadColumnData(
    boardId,
    column,
    itemsValues,
    statusesByColumn
  );

  return {
    itemStatusInfo,
    statusesByColumn: updatedStatusesByColumn,
  };
}

export async function getAllItemsValue(boardId: number, columns: string[]) {
  const pages = Array(Math.ceil(MONDAY_CONSTANTS.MAX_ITEMS_ON_BOARD / MONDAY_CONSTANTS.ITEMS_PER_REQUEST)).fill(0);
  const itemsCount = await getItemsCount(boardId);
  const itemsComplexity = getItemsComplexity(itemsCount);

  await checkEnoughComplexity(itemsComplexity);
  return await Promise.all(
    pages.map((_, i) => getItemsValue(boardId, columns, i + 1, MONDAY_CONSTANTS.ITEMS_PER_REQUEST))
  );
}

export async function isEnoughComplexityForInitUpdate() {
  const { complexity } = await getComplexity();
  const { before, resetInXSeconds } = complexity;

  const neededComplexity = MONDAY_CONSTANTS.ITEMS_COMPLEXITY + MONDAY_CONSTANTS.MAX_LOGS_COMPLEXITY;
  return { isEnough: before >= neededComplexity, resetInSeconds: resetInXSeconds };
}

export function getPriorityColumn(statusColumns: Column[]) {
  const priorityColumns = statusColumns
    .filter((column) => statusColumnsPriorities.includes(column.id))
    .reduce((acc, status) => {
      const priority = statusColumnsPriorities.indexOf(status.id);
      acc[priority] = status;
      return acc;
    }, [] as Column[])
    .filter(Boolean);

  return priorityColumns[0] || null;
}

export function getPriorityBoard(boardsInfo: BoardInfo[]) {
  const priorityBoards = boardsInfo.reduce(
    (acc, board) => {
      const identifiedPriorityBoard = boardsPriorities.find((priorityBoard) =>
        board.name.toLowerCase().startsWith(priorityBoard)
      ) as keyof typeof acc | undefined;

      if (!identifiedPriorityBoard) {
        return acc;
      }

      acc[identifiedPriorityBoard].push(board);
      return acc;
    },
    { opportunities: [], deals: [], leads: [] } as {
      opportunities: BoardInfo[];
      deals: BoardInfo[];
      leads: BoardInfo[];
    }
  );

  const sortedPriorityBoards = Object.values(priorityBoards)
    .map((boards) => boards.sort((a, b) => a.name.localeCompare(b.name)))
    .filter((boards) => boards.length);

  return sortedPriorityBoards[0]?.[0] || null;
}

function getItemsComplexity(itemsCount: number) {
  if (!itemsCount) return MONDAY_CONSTANTS.ITEMS_COMPLEXITY_DEFAULT;

  return Math.ceil(itemsCount / MONDAY_CONSTANTS.ITEMS_PER_REQUEST) * MONDAY_CONSTANTS.ITEMS_COMPLEXITY_DEFAULT;
}

type ColumnItemsInfo = Map<number, ItemInfo>;

export type ItemInfo = {
  itemId: string;
  statusId: number;
  statusLabel: string;
  userIds: number[];
  createdDate: number;
  logs: {
    itemId: string;
    statusId: string;
    createdAt: string;
    previousValue: null | { statusId: number; varName: string };
  }[];
  numberesById: { [key: string]: number };
};
