import { t } from 'i18next';
import { Toast } from 'monday-ui-react-core';
import { useCallback } from 'react';

const TIME_TO_RELOAD = 1000 * 60;

export const ErrorToast = ({ errorMessage }: { errorMessage: string | null }) => {
  const onCloseCallback = useCallback(() => {
    if (errorMessage) {
      setTimeout(() => {
        window.location.reload();
      }, TIME_TO_RELOAD);
      return;
    }
    window.location.reload();
  }, [errorMessage]);

  return (
    <Toast open={true} closeable={false} onClose={onCloseCallback} autoHideDuration={5000} type={Toast.types.NEGATIVE}>
      {t<string>(errorMessage || 'error_toast.message')}
    </Toast>
  );
};
