import {
  CALCUTATION_TYPES,
  COLUMN_TYPE_NUMBER,
  COLUMN_TYPE_PERSON,
  COLUMN_TYPE_STATUS,
  DISPLAY_OPTIONS_VALUES,
  ERROR_CONSTANTS,
  STATUS_COLUMN_STATE,
} from '../../constants';
import { statusesTemplates } from '../../constants/templates';
import { getBoardsInfo, getBoardStructure, getContext, getUsers } from '../../services/monday/monday-api';
import { MONDAY_CONSTANTS } from '../../services/monday/monday-constants';
import {
  getAllItemsValue,
  getColumnAllItemsInfo,
  getPriorityBoard,
  getPriorityColumn,
  ItemInfo,
} from '../../services/monday/monday-service';
import { Column, ItemValue, SettingsConfig, StatusColumnValue } from '../../types';
import {
  areUnexistedLabels,
  date,
  FatalError,
  getLabelId,
  removeObjectProperty,
  storage,
  toCamelCase,
} from '../../utils';

export async function getInitializedFunnel(boardId?: number) {
  const { boardIds } = (await getContext()).data;

  if (!boardIds.length) {
    throw new FatalError(ERROR_CONSTANTS.BOARD_ERROR);
  }

  const selectedBoardId = (await storage.getFromMonday(MONDAY_CONSTANTS.SELECTED_BOARD_KEY))?.id;

  await storage.cleanCache(boardIds);

  if (!boardId && !selectedBoardId && boardIds.length > 1) {
    const boardsInfo = await getBoardsInfo(boardIds);
    const priorityBoard = getPriorityBoard(boardsInfo);
    return await getBoardData(Number(priorityBoard?.id) || boardIds[0]);
  }

  if (!boardId && !selectedBoardId) {
    return await getBoardData(boardIds[0]);
  }

  if (selectedBoardId && !boardIds.includes(Number(selectedBoardId))) {
    return await getBoardData(boardIds[0]);
  }

  if (!boardId) {
    const boardId = selectedBoardId ? Number(selectedBoardId) : boardIds[0];
    return await getBoardData(boardId);
  }

  return await getBoardData(boardId);
}

async function getBoardData(boardId: number) {
  const savedSettings = await storage.getFromMonday(String(boardId));
  const savedLogs = await storage.getBoardCache(boardId);

  const context = (await getContext()).data;
  const { boardIds } = context;
  const { timeZoneOffset } = context.user;

  const boardsInfo = await getBoardsInfo(boardIds);
  const columns = await getBoardStructure(boardId);
  const statusColumns = columns.filter((column) => column.type === COLUMN_TYPE_STATUS);
  const personColumns = columns.filter((column) => column.type === COLUMN_TYPE_PERSON);
  const numberColumns = columns.filter((column) => column.type === COLUMN_TYPE_NUMBER);

  const selectedColumn = getSelectedColumn(statusColumns, savedSettings?.selectedColumn);

  if (!selectedColumn) {
    throw new FatalError(ERROR_CONSTANTS.STATUS_ERROR);
  }

  if (savedSettings?.boardId && savedLogs?.id === boardId && savedLogs?.cache[selectedColumn?.id]) {
    return {
      funnel: {
        boardId,
        personColumns,
        itemStatusInfo: savedLogs.cache,
        timeZoneOffset,
      },
      settings: {
        statusColumns,
        selectedColumn,
        statusesByColumn: {
          ...savedSettings?.statusesByColumn,
          [selectedColumn.id]: getStatusesByColumn(selectedColumn, savedSettings.statusesByColumn),
        },
        users: savedSettings.users,
        displayOption: savedSettings.displayOption,
        numberColumns,
        calculationType: savedSettings.calculationType,
        selectedNumberColumn: savedSettings.selectedNumberColumn,
        selectedDate:
          date.getDateOptions(timeZoneOffset).find((option) => option.label === savedSettings?.selectedDate?.label) ||
          null,
        selectedUser: savedSettings.selectedUser,
        boardsInfo,
        selectedBoardId: String(boardId),
      },
    };
  }

  const itemsValues = (
    await getAllItemsValue(boardId, [
      selectedColumn.id,
      ...personColumns.map((column) => column.id),
      ...numberColumns.map((column) => column.id),
    ])
  ).filter((x) => x.length);

  const allUsers = await getUsers();

  const assignedUsers = getAssignedUsers(itemsValues.flat(), allUsers);

  const { itemStatusInfo, statusesByColumn } = await loadColumnData(
    boardId,
    selectedColumn,
    itemsValues,
    savedSettings?.statusesByColumn
  );

  await storage.setBoardCache(boardId, itemStatusInfo);
  await storage.setToMonday(MONDAY_CONSTANTS.SELECTED_BOARD_KEY, { id: boardId });

  return {
    funnel: {
      boardId: boardId,
      personColumns,
      itemStatusInfo,
      timeZoneOffset,
    },
    settings: {
      statusColumns,
      selectedColumn,
      users: assignedUsers,
      statusesByColumn,
      numberColumns,
      boardsInfo,
      selectedBoardId: String(boardId),
      selectedDate: null,
      selectedNumberColumn: null,
      selectedUser: null,
      isUpdating: false,
      calculationType: CALCUTATION_TYPES.ITEMS,
      displayOption: DISPLAY_OPTIONS_VALUES.FINAL_STAGE_COLOR,
    },
  };
}

export async function updateFunnelInfo(
  boardId: number,
  column: Column,
  statusByColumn: StatusesByColumn,
  columnItemsStatusInfo: Record<string, ItemInfo[]>,
  numberSelectedColumn: Column
) {
  const columns = await getBoardStructure(boardId);
  const statusColumns = columns.filter((column) => column.type === COLUMN_TYPE_STATUS);
  const numberColumns = columns.filter((column) => column.type === COLUMN_TYPE_NUMBER);

  await storage.cleanBoardCache(
    boardId,
    statusColumns.map((column) => column.id)
  );

  if (!statusColumns.length) {
    throw new FatalError(ERROR_CONSTANTS.STATUS_ERROR);
  }

  const selectedColumn = statusColumns.find((status) => status.id === column.id) || null;
  const selectedNumberColumn = numberColumns.find((status) => status.id === numberSelectedColumn?.id) || null;

  if (!selectedColumn) {
    return [
      {
        funnel: {
          boardId,
          itemStatusInfo: removeObjectProperty(columnItemsStatusInfo, column.id),
        },
        settings: {
          boardId,
          selectedColumn,
          statusColumns,
          statusesByColumn: removeObjectProperty(statusByColumn, column.id),
          selectedNumberColumn,
          numberColumns,
        },
      },
      { exists: false, id: column.id },
    ];
  }

  const personColumns = columns.filter((column) => column.type === COLUMN_TYPE_PERSON);
  const itemsValues = (
    await getAllItemsValue(boardId, [
      column.id,
      ...personColumns.map((column) => column.id),
      ...numberColumns.map((column) => column.id),
    ])
  ).filter((x) => x.length);
  const allUsers = await getUsers();
  const assignedUsers = getAssignedUsers(itemsValues.flat(), allUsers);

  const { itemStatusInfo, statusesByColumn } = await loadColumnData(
    boardId,
    selectedColumn,
    itemsValues,
    statusByColumn
  );

  await storage.setBoardCache(boardId, itemStatusInfo);

  return [
    {
      funnel: {
        boardId,
        itemStatusInfo,
        personColumns,
      },
      settings: {
        boardId,
        statusColumns,
        selectedColumn,
        users: assignedUsers,
        statusesByColumn,
        selectedNumberColumn,
        numberColumns,
      },
    },
    { exists: true, id: column.id },
  ];
}

export async function loadColumnData(
  boardId: number,
  column: Column,
  itemsValues: ItemValue[][],
  statusesByColumn?: StatusesByColumn
) {
  const columnItemsInfo = await getColumnAllItemsInfo(boardId, column, itemsValues);

  return {
    statusesByColumn: {
      [column.id]: getStatusesByColumn(column, statusesByColumn),
    },
    itemStatusInfo: {
      [column.id]: columnItemsInfo,
    },
  };
}

export function setOrderById(orderedIds: string[], array: StatusColumnValue[]) {
  const extraColumns = array.slice();
  for (let id of orderedIds.reverse()) {
    const index = extraColumns.findIndex((extraColumn: any) => extraColumn.id === id);
    const [status] = extraColumns.splice(index, 1);
    extraColumns.unshift(status);
  }
  return extraColumns;
}

export function getUpdatedStatusesOrder(updatedFunnel: any, statusesByColumn: StatusesByColumn): StatusColumnValue[] {
  const columnId = updatedFunnel.selectedColumn.id;
  const { updatedStatuses, unexistingStatuses } = updatedFunnel.statusesByColumn[columnId].reduce(
    (
      acc: {
        updatedStatuses: StatusColumnValue[];
        unexistingStatuses: StatusColumnValue[];
      },
      status: StatusColumnValue
    ) => {
      const index = statusesByColumn[columnId].findIndex((x) => x.id === status.id);
      if (index === -1) {
        acc.unexistingStatuses.push(status);
        return acc;
      }
      acc.updatedStatuses[index] = {
        id: status.id,
        label: status.label,
        state: statusesByColumn[columnId].find((x) => x.id === status.id)!.state,
      };
      return acc;
    },
    {
      updatedStatuses: [],
      unexistingStatuses: [],
    }
  );
  return [...updatedStatuses, ...unexistingStatuses];
}

function getSelectedColumn(statusColumns: Column[], savedSelectedColumn?: Column) {
  const selectedColumn = statusColumns.find((column) => column.id === savedSelectedColumn?.id);

  return selectedColumn ? selectedColumn : getDefaultStatusColumn(statusColumns);
}

function getStatusesByColumn(column: Column, statusesByColumn?: StatusesByColumn) {
  const config: SettingsConfig = toCamelCase(JSON.parse(column.settingsStr));
  const existingStatusByColumn = statusesByColumn?.[column.id];

  return !existingStatusByColumn
    ? getDefaultStatuses(column)
    : getUpdatedStatusesByColumn(existingStatusByColumn, config);
}

function getUpdatedStatusesByColumn(
  statusByColumn: StatusColumnValue[],
  statusConfig: SettingsConfig
): StatusColumnValue[] {
  const statusIds = Object.keys(statusConfig.labels);

  const unexistingStatuses = statusIds
    .filter((id) => !statusByColumn.find((status) => status.id === id))
    .map((id) => ({
      id: id,
      label: statusConfig.labels[id],
      state: STATUS_COLUMN_STATE.UNSELECTED,
    }));

  const updatedStatusesByColumn = statusByColumn.reduce((acc, status) => {
    const statusLabel = statusConfig.labels[status.id];
    const isDefaultStatus =
      (statusLabel === undefined && Number(status.id) === getLabelId(JSON.stringify(statusConfig))) ||
      Number(status.id) === -1;

    if (isDefaultStatus) {
      acc.push({
        ...status,
        id: String(getLabelId(JSON.stringify(statusConfig), Number(status.id))),
      });

      return acc;
    }

    if (statusLabel === undefined) return acc;
    const label = !statusLabel ? 'N/A' : statusLabel;
    acc.push({
      ...status,
      label,
    });
    return acc;
  }, [] as StatusColumnValue[]);

  return [...updatedStatusesByColumn, ...unexistingStatuses];
}

function getDefaultStatusColumn(statusColumns: Column[]): Column | null {
  const priorityStatusColumn = getPriorityColumn(statusColumns);
  const column = statusColumns.find((statusColumn) => statusColumn.name === 'status') || statusColumns[0] || null;
  return priorityStatusColumn || column;
}

function getLabelIdByTitle(config: SettingsConfig, title: string): string | null {
  for (const key of Object.keys(config.labels)) {
    if (config.labels[key] === title) {
      return key;
    }
  }

  return null;
}

function getDefaultStatuses(column: Column) {
  let statuses: StatusColumnValue[] = [];
  const config: SettingsConfig = toCamelCase(JSON.parse(column.settingsStr));
  const positions = getPositions(config);
  const defaultLabelId = getLabelId(JSON.stringify(config));

  const columnId = column.id;

  if (statusesTemplates[columnId]) {
    for (const status of statusesTemplates[columnId]) {
      const statusId = getLabelIdByTitle(config, status.title);

      if (statusId === null && status.title === 'N/A' && defaultLabelId !== -1) {
        const defaultLabelTitle = getStatusLabel(config, String(defaultLabelId));

        if (defaultLabelTitle !== status.title) continue;

        statuses.push({
          id: String(defaultLabelId),
          label: defaultLabelTitle,
          state: status.state,
        });
      }

      if (statusId) {
        statuses.push({
          id: String(statusId),
          label: getStatusLabel(config, statusId),
          state: status.state,
        });
      }
    }
    for (const [id] of positions) {
      const isStatusInArray = statuses.find((status) => status.id === id);
      if (!isStatusInArray) {
        statuses.push({
          id: String(id),
          label: getStatusLabel(config, id),
          state: STATUS_COLUMN_STATE.UNSELECTED,
        });
      }
    }
  } else {
    for (const [id, position] of positions) {
      statuses[position] = {
        id: String(id),
        label: getStatusLabel(config, id),
        state: STATUS_COLUMN_STATE.SELECTED,
      };
    }
  }

  if (defaultLabelId === -1) {
    statuses.unshift({
      id: '-1',
      label: 'N/A',
      state: STATUS_COLUMN_STATE.UNSELECTED,
    });
  }

  return statuses.filter(Boolean);
}

function getPositions(config: SettingsConfig): [string, number][] {
  const unexistedLabels = areUnexistedLabels(
    Object.keys(config.labelsColors),
    Object.keys(config?.labelsPositionsV2 || {})
  );

  if (config.labelsPositionsV2 && !unexistedLabels) {
    return Object.entries(config.labelsPositionsV2).map(([id, position]) => [String(id), Number(position)]);
  } else {
    return Object.keys(config.labels).map((key, index) => [String(key), index]);
  }
}

function getStatusLabel(config: SettingsConfig, id: string): string {
  return config.labels[id] || 'N/A';
}

export function getAssignedUsers(itemsValue: ItemValue[], allUsers: any[]) {
  const asignedUserIds = itemsValue.flatMap((personItem) =>
    personItem.columnValues
      .filter((columnValue) => columnValue.value)
      .flatMap((colValue) => JSON.parse(colValue.value).personsAndTeams || [])
      .map((user) => user.id)
  );

  return allUsers.filter((user) => asignedUserIds.includes(user.id));
}

export type StatusesByColumn = Record<string, StatusColumnValue[]>;

export type ExistingSelectedColumn = { exists: boolean; id: string };
