import { DEFAULT_LABEL_COLOR } from '../constants';
import { toCamelCase } from './formatting';

export function removeObjectProperty(obj: Record<string, unknown>, property: string) {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== property));
}

export function roundNumber(x: number) {
  return Math.round(x * 10) / 10;
}

export function getConversionPercent(conversion: any) {
  return roundNumber((conversion[conversion.length - 1]?.count * 100) / conversion[0]?.count || 0);
}

export function getLabelId(config?: string, statusId?: number): number {
  if (!config) return -1;

  const settings = toCamelCase(JSON.parse(config));

  if (typeof statusId === 'number' && statusId !== -1) {
    const labelId = settings?.labelsPositionsV2 ? settings.labelsPositionsV2[statusId] : settings.labels[statusId];

    return labelId || labelId === 0 ? Number(statusId) : -1;
  }

  const labelColorsIds = Object.keys(settings.labelsColors);
  const labelsPositionsV2Ids = Object.keys(settings?.labelsPositionsV2 || {});

  for (const key of labelColorsIds) {
    if (settings.labelsColors[key].varName === DEFAULT_LABEL_COLOR) {
      return Number(key);
    }
  }

  if (settings?.labelsPositionsV2 && !areUnexistedLabels(labelColorsIds, labelsPositionsV2Ids)) {
    for (const key of labelsPositionsV2Ids) {
      if (!Object.keys(settings.labels).includes(String(key))) {
        return Number(key);
      }
    }
  }

  return -1;
}

export function areUnexistedLabels(labelsColors: string[], labelsPositionsV2: string[]) {
  if (!labelsPositionsV2.length) return false;

  return labelsPositionsV2.length - labelsColors.length > 1;
}

export function isOverflowActive(event: any) {
  return event.offsetWidth < event.scrollWidth;
}
