import { ERROR_CONSTANTS } from '../constants';
import { FatalError } from './error';

export async function retry<T>(fn: () => Promise<T>, retries = 2): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    console.log(error);

    if (retries <= 0) {
      throw new FatalError(ERROR_CONSTANTS.MONDAY_ERROR);
    }

    return await retry(fn, retries - 1);
  }
}
