import React, { createContext, useMemo, useState } from 'react';

import { CALCUTATION_TYPES, STATUS_COLUMN_STATE, UI_MODE } from '../constants';
import { getMode } from '../services/config.service';
import { ItemInfo } from '../services/monday/monday-service';
import { Column, MondayStorageSettings } from '../types';
import { getConversionPercent, getLabelId, initSettings } from '../utils';
import { useSettings } from './hooks';
import {
  calculateStatusesCount,
  getAverageConversion,
  getMedianConversion,
  getStatusConversion,
  getSumConversion,
  groupStatusesNumbers,
  StatusConversion,
} from './services/conversion';
import { filterByDate, filterByPerson, nativeFilter } from './services/filters';
import { ExistingSelectedColumn, getInitializedFunnel, StatusesByColumn } from './services/funnel';
import { SettingsState } from './SettingsContext';

type ColumnData = { statusesByColumn: StatusesByColumn; itemStatusInfo: Record<string, ItemInfo[]> };

export interface FunnelState {
  initialized: boolean;
  isReloading: boolean;
  boardId: number | null;
  personColumns: Column[];
  itemStatusInfo: Record<string, ItemInfo[]>;
  itemIds: number[];
  timeZoneOffset: number;
}

export interface IFunnelContext {
  funnelState: FunnelState;
  statusConversion: { conversion: StatusConversion[]; conversionPercent: number };
  mondayStorageSettings: MondayStorageSettings;
  funnelInit(): Promise<void>;
  syncModesStates({ funnel, settings }: { funnel: FunnelState; settings: SettingsState }): void;
  setSelectedColumnData(columnData: ColumnData): void;
  setUpdatedFunnelState(updatedFunnel: FunnelState, column: ExistingSelectedColumn): void;
  setItemsIds(itemIds: number[]): void;
  setFunnelState: React.Dispatch<React.SetStateAction<FunnelState>>;
}

export const FunnelContext = createContext({} as IFunnelContext);

const FunnelProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [funnelState, setFunnelState] = useState({
    boardId: null,
    initialized: false,
    itemStatusInfo: {},
    personColumns: [],
    isReloading: false,
    itemIds: [],
    timeZoneOffset: 0,
  } as FunnelState);
  const { settingsState, settingsInit, setSettingsState } = useSettings();

  const filteredItems = useMemo(() => {
    const columnId = settingsState.selectedColumn?.id;
    if (!columnId || !funnelState.itemStatusInfo[columnId]) return [];

    const nativeFilteredItems = nativeFilter(funnelState.itemStatusInfo[columnId], funnelState.itemIds);
    const defaultStatusId = getLabelId(settingsState.selectedColumn?.settingsStr);
    const filterByDateItems = filterByDate(
      nativeFilteredItems,
      settingsState.statusesByColumn[columnId],
      defaultStatusId,
      settingsState.selectedDate?.value
    );

    return filterByPerson(filterByDateItems, settingsState.selectedUser);
  }, [
    funnelState.itemIds,
    funnelState.itemStatusInfo,
    settingsState.selectedColumn,
    settingsState.selectedDate?.value,
    settingsState.selectedUser,
    settingsState.statusesByColumn,
  ]);

  const itemsConversion = useMemo(() => {
    const columnId = settingsState.selectedColumn?.id;
    const numberColId = settingsState.selectedNumberColumn?.id;

    if (!funnelState.initialized || !columnId) return [];

    if (!settingsState.statusesByColumn[columnId]) {
      setFunnelState((state) => ({ ...state, isReloading: true }));
      return [];
    }
    const calculationSum = groupStatusesNumbers(filteredItems, settingsState.statusesByColumn[columnId], numberColId);

    const statusesCount = calculateStatusesCount(filteredItems, settingsState.statusesByColumn[columnId]);
    const statusOrder = settingsState.statusesByColumn[columnId]
      .map((status) => {
        return {
          ...status,
          count: statusesCount[status?.id] || 0,
          numbers: calculationSum[status?.id] || [],
        };
      })
      .filter((status) => status.state !== STATUS_COLUMN_STATE.UNSELECTED);

    return getStatusConversion(statusOrder);
  }, [
    settingsState.selectedColumn,
    settingsState.selectedNumberColumn,
    settingsState.statusesByColumn,
    funnelState.initialized,
    filteredItems,
  ]);

  const statusConversion = useMemo(() => {
    const numberColId = settingsState.selectedNumberColumn?.id;
    let conversionPercent = getConversionPercent(itemsConversion);
    if (!numberColId) {
      return { conversion: itemsConversion, conversionPercent };
    }

    const sumConversion = getSumConversion(itemsConversion);
    conversionPercent = getConversionPercent(sumConversion);

    switch (settingsState.calculationType) {
      case CALCUTATION_TYPES.AVERAGE:
        return { conversion: getAverageConversion(sumConversion), conversionPercent };
      case CALCUTATION_TYPES.MEDIAN:
        return { conversion: getMedianConversion(sumConversion), conversionPercent };
      default:
        return { conversion: sumConversion, conversionPercent };
    }
  }, [settingsState.calculationType, settingsState.selectedNumberColumn?.id, itemsConversion]);

  const mondayStorageSettings = useMemo(() => {
    return {
      boardId: funnelState.boardId,
      calculationType: settingsState.calculationType,
      displayOption: settingsState.displayOption,
      selectedColumn: settingsState.selectedColumn,
      selectedDate: settingsState.selectedDate,
      selectedNumberColumn: settingsState.selectedNumberColumn,
      selectedUser: settingsState.selectedUser,
      statusesByColumn: settingsState.statusesByColumn,
      users: settingsState.users,
    };
  }, [
    funnelState.boardId,
    settingsState.calculationType,
    settingsState.displayOption,
    settingsState.selectedColumn,
    settingsState.selectedDate,
    settingsState.selectedNumberColumn,
    settingsState.selectedUser,
    settingsState.statusesByColumn,
    settingsState.users,
  ]);

  async function funnelInit() {
    const { funnel, settings } = getMode() === UI_MODE.NORMAL ? await getInitializedFunnel() : await initSettings();

    setFunnelState((state) => ({ ...state, ...funnel, initialized: true }));
    settingsInit(settings);
  }

  function syncModesStates({ funnel, settings }: { funnel: FunnelState; settings: SettingsState }) {
    setFunnelState((state) => ({ ...state, ...funnel }));
    setSettingsState((state) => ({ ...state, ...settings }));
  }

  function setSelectedColumnData(columnData: ColumnData) {
    const { statusesByColumn, itemStatusInfo } = columnData;
    setFunnelState((state) => ({
      ...state,
      itemStatusInfo: { ...state.itemStatusInfo, ...itemStatusInfo },
      isReloading: false,
    }));
    setSettingsState((state) => ({
      ...state,
      statusesByColumn: { ...state.statusesByColumn, ...statusesByColumn },
    }));
  }

  function setUpdatedFunnelState(updatedFunnel: FunnelState, column: ExistingSelectedColumn) {
    if (Number(settingsState.selectedBoardId) !== updatedFunnel.boardId) {
      return;
    }

    if (column.exists) {
      setFunnelState((state) => ({
        ...state,
        personColumns: updatedFunnel.personColumns,
        itemStatusInfo: {
          ...state.itemStatusInfo,
          ...updatedFunnel.itemStatusInfo,
        },
      }));
    } else {
      setFunnelState((state) => ({
        ...state,
        itemStatusInfo: updatedFunnel.itemStatusInfo,
      }));
    }
  }

  function setItemsIds(itemIds: number[]) {
    setFunnelState((state) => ({ ...state, itemIds }));
  }

  return (
    <FunnelContext.Provider
      value={{
        funnelState,
        statusConversion,
        mondayStorageSettings,
        funnelInit,
        syncModesStates,
        setSelectedColumnData,
        setUpdatedFunnelState,
        setItemsIds,
        setFunnelState,
      }}
    >
      {children}
    </FunnelContext.Provider>
  );
};

export default FunnelProvider;
