import { createRoot } from 'react-dom/client';

import App from './App';
import { RootProvider } from './contexts/index';

import './i18n';

import './index.scss';

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <RootProvider>
    <App />
  </RootProvider>
);
