import { t } from 'i18next';
import { Button } from 'monday-ui-react-core';
import { Settings } from 'monday-ui-react-core/dist/allIcons';

import { ReactComponent as EmptyImg } from '../../assets/empty_screen.svg';
import { useNavigation } from '../../contexts/hooks';

import './EmptyConfigMessage.scss';

export const EmptyConfigMessage: React.FC = () => {
  const { openSettings, isSettingsMode } = useNavigation();

  function handleSettingsclick() {
    openSettings();
  }

  return (
    <div className="empty-screen">
      <div className="empty-screen_container">
        <EmptyImg className="empty-screen_img" />
        <div className="empty-screen_aside">
          <h3 className="empty-screen_title">{t<string>('empty_screen.check_settings')}</h3>
          {!isSettingsMode && (
            <Button leftIcon={Settings} onClick={handleSettingsclick}>
              {t<string>('empty_screen.go_to_settings')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
