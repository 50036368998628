import cn from 'classnames';
import { Tooltip } from 'monday-ui-react-core';
import React from 'react';

import './ChartBar.scss';

interface ChartBarProps {
  percentFromFirst: number;
  count: number | string;
  label: string;
  isLast: boolean;
  nextItemPercent: number;
  startPoint: number;
  endPoint: number;
  bgColor: string;
  statusCount: number;
  columnLabel?: string;
  tooltipCount: string;
}

export const ChartBar: React.FC<ChartBarProps> = ({
  percentFromFirst,
  count,
  label,
  isLast,
  nextItemPercent,
  startPoint,
  endPoint,
  bgColor,
  statusCount,
  columnLabel,
  tooltipCount,
}) => {
  function getBarTitleQueryClassName() {
    if (statusCount <= 4) {
      return 'title__media-query__lg';
    } else if (statusCount > 4 && statusCount < 7) {
      return 'title__media-query__md';
    } else {
      return 'title__media-query__sm';
    }
  }

  return (
    <>
      <Tooltip
        content={
          <>
            <div className="tooltip-title">
              <div className="tooltip-dot" style={{ backgroundColor: bgColor }}></div>
              <div className="tooltip-label">{label}</div>
            </div>
            <div>
              {columnLabel || ''} Value: <span className="tooltip-amount">{tooltipCount}</span>
            </div>
          </>
        }
      >
        <div
          className="bar"
          style={{ height: `${percentFromFirst === 0 ? 0.5 : percentFromFirst}%`, backgroundColor: bgColor }}
        >
          <div className="bar--count primary-text-color">{count}</div>
          <div className={cn('bar--title', 'primary-text-color', getBarTitleQueryClassName())}>{label}</div>
        </div>
      </Tooltip>
      {!isLast && (
        <div className="bar_polygon-wrapper">
          <div
            className="bar_polygon_arrow primary-text-color"
            style={{ bottom: `max(0px, calc(${100 - endPoint}% - 12px))` }}
          >{` ${nextItemPercent}%`}</div>

          <div
            className="bar_polygon primary-text-color"
            style={{
              clipPath: `polygon(0 ${startPoint}%, 100% ${endPoint}%, 100% 100%, 0% 100%)`,
              backgroundColor: bgColor,
            }}
          />
        </div>
      )}
    </>
  );
};
