import Dexie from 'dexie';

import { getItem, setItem } from '../services/monday/monday-api';

const db: any = new Dexie('funnel-widget-db');
db.version(1).stores({
  funnelState: 'id, key, state',
});

const { funnelState } = db;

export default class CustomStorage {
  async setToMonday(settingKey: string, state: any) {
    const stateStringify = JSON.stringify(state);
    await setItem(settingKey, stateStringify);
  }

  async getFromMonday(settingKey: string) {
    const json = await getItem(settingKey);
    const parsedState = JSON.parse(json || 'false');
    return parsedState;
  }

  async cleanCache(boardIds: number[]) {
    const cachedStatusIds: number[] = await funnelState.toCollection().keys();
    const statusIdsToRemove = cachedStatusIds.filter((id) => !boardIds.includes(id));
    await Promise.all(statusIdsToRemove.map(this.deleteRowById));
  }

  async deleteRowById(id: number) {
    await funnelState.where('id').equals(id).delete();
  }

  async cleanBoardCache(boardId: number, statusIds: string[]) {
    const boardCache = await this.getBoardCache(boardId);
    if (!boardCache?.id) return;
    const filteredColumns = Object.fromEntries(
      Object.entries(boardCache.cache).filter(([key]) => statusIds.includes(key))
    );
    await this.updateBoardCache(boardCache.id, JSON.stringify(filteredColumns));
  }

  async getBoardCache(id: number) {
    const cachedBoard = await funnelState.get({ id });
    const parsedState = JSON.parse(cachedBoard?.state || '{}');
    return { id: cachedBoard?.id, cache: parsedState };
  }

  private async updateBoardCache(id: number, state: string) {
    await funnelState.update(id, { state: state });
  }

  async setBoardCache(key: number, state: any) {
    const boardCache = await this.getBoardCache(key);

    if (boardCache?.id === key) {
      const mergedState = JSON.stringify({ ...boardCache.cache, ...state });
      await this.updateBoardCache(boardCache.id, mergedState);
      return;
    }

    const stateStringify = JSON.stringify(state);
    await funnelState.add({
      id: key,
      state: stateStringify,
    });
  }
}

export const storage = new CustomStorage();
