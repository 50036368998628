import { Avatar } from 'monday-ui-react-core';
import React, { useEffect, useRef, useState } from 'react';

import { isOverflowActive } from '../../utils';
import { WidgetTooltip } from '../WidgetTooltip/WidgetTooltip';

import './OptionsRenderer.scss';

type OptionRendererProps = { src: string; type: string; size: string; label: string };

export const OptionRenderer: React.FC<OptionRendererProps> = ({ src, type, size, label }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    if (divRef.current && isOverflowActive(divRef.current)) {
      setTooltip(true);
    }
  }, [divRef]);

  return (
    <WidgetTooltip content={label} enable={tooltip}>
      <div className="inline-container" key={label}>
        <Avatar size={size} src={src} type={type} key={label} />
        <div ref={divRef} className="name">
          {label}
        </div>
      </div>
    </WidgetTooltip>
  );
};
