import { STATUS_COLUMN_STATE } from './column-constants';

export const statusesTemplates: Record<string, { title: string; state: string }[]> = {
  lead_status: [
    { title: 'N/A', state: STATUS_COLUMN_STATE.UNSELECTED },
    { title: 'New Lead', state: STATUS_COLUMN_STATE.SELECTED },
    { title: 'Unqualified', state: STATUS_COLUMN_STATE.UNSELECTED },
    { title: 'Attempted to contact', state: STATUS_COLUMN_STATE.SELECTED },
    { title: 'Contacted', state: STATUS_COLUMN_STATE.SELECTED },
    { title: 'Qualified', state: STATUS_COLUMN_STATE.SELECTED },
  ],
  deal_stage: [
    { title: 'N/A', state: STATUS_COLUMN_STATE.UNSELECTED },
    { title: 'New', state: STATUS_COLUMN_STATE.SELECTED },
    { title: 'Lost', state: STATUS_COLUMN_STATE.UNSELECTED },
    { title: 'Discovery', state: STATUS_COLUMN_STATE.SELECTED },
    { title: 'Proposal', state: STATUS_COLUMN_STATE.SELECTED },
    { title: 'Negotiation', state: STATUS_COLUMN_STATE.SELECTED },
    { title: 'Won', state: STATUS_COLUMN_STATE.SELECTED },
  ],
};

export const boardsPriorities = ['opportunities', 'deals', 'leads'];
export const statusColumnsPriorities = ['deal_stage', 'lead_status'];
