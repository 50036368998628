import { DEFAULT_LABEL_COLOR, STATUS_COLUMN_STATE } from '../../constants';
import { ItemInfo } from '../../services/monday/monday-service';
import { StatusColumnValue } from '../../types';
import { User } from '../SettingsContext';

export function filterByPerson(itemStatusInfo: ItemInfo[], selectedUser: User | null) {
  if (!selectedUser?.id) return itemStatusInfo;

  return itemStatusInfo.filter((item) => item.userIds.includes(selectedUser.id));
}

export function filterByDate(
  itemStatusInfo: ItemInfo[],
  statusesOrder: StatusColumnValue[],
  defaultStatusId: number,
  filteredDate?: number
) {
  if (!filteredDate) return itemStatusInfo;

  const firstStatusSelected = statusesOrder[0].state === STATUS_COLUMN_STATE.SELECTED;

  if (firstStatusSelected) {
    return itemStatusInfo.filter((item) => Number(item.createdDate) >= filteredDate);
  }

  if (!firstStatusSelected) {
    const passedItems = itemStatusInfo.filter((item) => Number(item.createdDate) >= filteredDate);
    const unpassedItems = itemStatusInfo.filter((item) => Number(item.createdDate) <= filteredDate);

    const filteredUnpassedItems = unpassedItems.filter((item) => {
      const id = statusesOrder[0].id;
      const log = item.logs.find((log) => {
        return (
          log.previousValue?.statusId === Number(id) ||
          (Number(id) === -1 && log.previousValue?.varName === DEFAULT_LABEL_COLOR) ||
          (log.previousValue === null && Number(id) === defaultStatusId)
        );
      });

      if (!log) return false;

      return Number(log.createdAt) >= filteredDate;
    });

    return [...passedItems, ...filteredUnpassedItems];
  }

  return itemStatusInfo;
}

export function nativeFilter(itemStatusInfo: ItemInfo[], itemIds: number[]) {
  return itemStatusInfo.filter((item) => itemIds.includes(Number(item.itemId)));
}
