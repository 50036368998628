import mondaySdk from 'monday-sdk-js';

import { Column, ItemValue } from '../../types';
import { delay, lastSixMothsToISO, retry,toCamelCase } from '../../utils';
import { MONDAY_CONSTANTS } from './monday-constants';

export const monday = mondaySdk();

export async function getBoardStructure(boardId: number): Promise<Column[]> {
  const query = `query ($boardId: [Int]) {
    boards (ids: $boardId) {
      columns {
        title
        type
        id
        settings_str
      }
    }
  }`;

  const variables = { boardId };
  const result = toCamelCase(await monday.api(query, { variables })) as any;

  return result.data.boards[0].columns;
}

export async function getItemsValue(
  boardId: number,
  columnId: string[],
  page: number,
  limit: number
): Promise<ItemValue[]> {
  const query = `query ($boardId: [Int], $column_id: [String]!, $page: Int!, $limit: Int!) {
      boards (ids: $boardId) {
        items(page: $page, limit: $limit) {
          id
          created_at
          column_values(ids:$column_id) {
            value
            text
            type
            id 
          }
        }
      }
    }`;

  const variables = { boardId, column_id: columnId, page, limit };
  const result = await retry<any>(() => monday.api(query, { variables }));
  return toCamelCase(result.data.boards[0].items);
}

export async function getUsers() {
  const query = `
    query {
      users {
        id
        name
        photo_thumb_small
      }
    }`;

  const response = toCamelCase(await monday.api(query)) as any;

  return response.data.users;
}

export async function getContext(): Promise<WidgetContext> {
  return await new Promise((res) => monday.listen('context', (data) => res(data as unknown as WidgetContext)));
}

export async function getActivityLog(
  boardId: number,
  columnId: string,
  itemsIds: number[],
  toDate?: string
): Promise<ActivityLog[]> {
  toDate = toDate ? toDate : new Date().toISOString();
  const from = lastSixMothsToISO();
  const limit = MONDAY_CONSTANTS.PER_PAGE_LIMIT;

  const query = `
      query($boardId: [Int], $toDate: ISO8601DateTime!, $from: ISO8601DateTime!,  $limit: Int!, $columnId: [String], $itemsIds: [Int]) {
        boards(ids: $boardId) {
          activity_logs(to: $toDate, from: $from, limit: $limit, column_ids: $columnId, item_ids: $itemsIds) {
            event
            data
            created_at
          }
        }
      }`;

  const variables = { boardId, columnId, toDate, from, limit, itemsIds };
  const result = await retry<any>(() => monday.api(query, { variables }));
  return toCamelCase(result.data.boards[0].activity_logs);
}

export async function checkEnoughComplexity(count: number) {
  const { complexity } = await getComplexity();
  const { before, resetInXSeconds } = complexity;
  if (before < count) {
    await delay(resetInXSeconds * 1000);
  }
}

export async function getComplexity() {
  const query = `query {
      complexity {
        before
        reset_in_x_seconds
      }
    }`;
  const result = (await monday.api(query, {})) as any;
  return toCamelCase(result.data);
}

export async function openModal(params?: ModalParams) {
  const { urlPath, urlParams, height, width } = params || {};
  // @ts-ignore
  return await monday.execute('openAppFeatureModal', { urlPath, urlParams, height, width });
}

export async function setItem(key: string, value: any) {
  return await monday.storage.instance.setItem(key, value);
}

export async function getItem(key: string) {
  const result = await monday.storage.instance.getItem(key);
  return result.data.value;
}

export async function getItemsCount(boardId: number) {
  const query = `query($boardId: [Int]) {
    boards (ids: $boardId) {
      items_count
    }
  }`;

  const variables = { boardId };

  const result = toCamelCase(await monday.api(query, { variables })) as any;
  return result.data.boards[0].itemsCount;
}

export async function getBoardsInfo(boardIds: number[]): Promise<BoardInfo[]> {
  const query = `query($boardIds: [Int]) {
    boards (ids: $boardIds) {
      id
      name
    }
  }`;
  const variables = { boardIds };
  const result = (await monday.api(query, { variables })) as any;
  return result.data.boards;
}

export type ActivityLog = {
  id: string;
  createdAt: string;
  data: string;
  event: string;
};

interface WidgetContext {
  data: {
    boardIds: number[];
    user: { timeZoneOffset: number };
  };
}

interface ModalParams {
  urlPath?: string;
  urlParams?: Record<string, string>;
  height?: number | string;
  width?: number | string;
}

export type BoardInfo = {
  id: string;
  name: string;
};
