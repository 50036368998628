import { t } from 'i18next';
import { Accordion, AccordionItem, ButtonGroup, Divider, RadioButton } from 'monday-ui-react-core';
import { Status } from 'monday-ui-react-core/dist/allIcons';
import { Counter } from 'monday-ui-react-core/dist/allIcons';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CALCUTATION_TYPES, DISPLAY_OPTIONS, DISPLAY_OPTIONS_VALUES, STATUS_COLUMN_STATE } from '../../constants';
import { useSettings } from '../../contexts/hooks';
import { Column, DropdownOption, StatusColumnValue } from '../../types';
import { CustomDropdown } from '../Dropdown/Dropdown';
import { ListItem, OrderedList } from '../OrderedList/OrderedList';
import { WidgetTooltip } from '../WidgetTooltip/WidgetTooltip';

import './Settings.scss';

const ACCORDIONS = {
  X_AXIS: 'x-axis',
  Y_AXIS: 'y-axis',
  DISPLAY_OPTIONS: 'display-options',
  BOARD_SELECTION: 'select_board',
};

export const Settings: React.FC = () => {
  const {
    settingsState,
    setStatusColumn,
    getSettingsStatuses,
    setStatusSelected,
    setStatusesOrder,
    setDisplayOption,
    setNumberColumn,
    setCalculationType,
    setSelectedBoard,
  } = useSettings();
  const selectedOption = columnToOption(settingsState.selectedColumn, <Status />);
  const statusColumnOptions = settingsState.statusColumns.map((column) => columnToOption(column, <Status />));
  const statuses = getSettingsStatuses().map(statusToListItem);

  const CALCULATION_FUNC_OPTIONS = [
    {
      value: CALCUTATION_TYPES.SUM,
      text: t('settings.sum'),
    },
    {
      value: CALCUTATION_TYPES.AVERAGE,
      text: t('settings.average'),
    },
    {
      value: CALCUTATION_TYPES.MEDIAN,
      text: t('settings.median'),
    },
  ];
  const COUNT_ITEM_OPTION = { value: '', label: t('settings.count_items') };

  const displayOptions = [
    {
      value: DISPLAY_OPTIONS_VALUES.SELECTED_COLUMN_COLOR,
      label: settingsState.selectedColumn?.title || '',
      icon: <Status />,
    },
    { value: DISPLAY_OPTIONS_VALUES.SINGLE_COLOR, label: 'Single color', intlLabel: t('settings.single_color') },
    {
      value: DISPLAY_OPTIONS_VALUES.FINAL_STAGE_COLOR,
      label: 'Final stage only',
      intlLabel: t('settings.final_stage'),
    },
  ];
  const [openedDropdown, setOpenedDropdown] = useState('');
  const accordionsRefs = useRef<any>([]);
  const dropdownsRefs = useRef<any>([]);
  const radioBtnsRefs = useRef<any>([]);
  const [radioBtnsTooltips, setRadioBtnsTooltips] = useState([]);

  useEffect(() => {
    if (dropdownsRefs.current[openedDropdown]) {
      dropdownsRefs.current[openedDropdown].scrollIntoView({ behavior: 'smooth' });
    }
  }, [openedDropdown]);

  useEffect(() => {
    setRadioBtnsTooltips(radioBtnsRefs.current);
  }, [radioBtnsRefs]);

  const selectedDisplayOption = useMemo(() => {
    if (settingsState.displayOption === DISPLAY_OPTIONS_VALUES.SELECTED_COLUMN_COLOR) {
      return {
        value: settingsState.displayOption,
        label: settingsState.selectedColumn?.title || '',
        icon: <Status />,
      };
    }
    return DISPLAY_OPTIONS.find((option) => option.value === settingsState.displayOption)!;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState.displayOption, settingsState.selectedColumn?.title]);
  const numberColumnOption = settingsState.numberColumns.map((column) => columnToOption(column, <Counter />));
  const selectedNumberColumnOption =
    columnToOption(settingsState.selectedNumberColumn, <Counter />) || COUNT_ITEM_OPTION;

  function handleColumnChange(option: DropdownOption) {
    setStatusColumn(option?.value);
  }

  function handleNumberColumnChange(option: DropdownOption) {
    setNumberColumn(option?.value);
  }

  function handleStatusColumnChange({ id, checked }: { id: string; checked: boolean }) {
    setStatusSelected(id, checked);
  }

  function handleStatusOrderChange(items: ListItem[]) {
    setStatusesOrder(items.map((x) => x.id));
  }

  function handleDisplayOptionChange(option: DropdownOption) {
    setDisplayOption(option?.value);
  }

  function handleBoardSelect(id: string) {
    setSelectedBoard(id);
  }

  function isBoardNameOverflowActive(event: any) {
    const span = event.firstChild.firstChild.nextSibling;
    return span.offsetWidth < span.scrollWidth;
  }

  return (
    <div className="settings">
      <div className="settings_wrapper">
        <Accordion
          defaultIndex={[0]}
          allowMultiple
          className="settings_accordion"
          ref={(element: HTMLDivElement) => (accordionsRefs.current[ACCORDIONS.X_AXIS] = element)}
        >
          <AccordionItem
            title={t('settings.x_axis')}
            onClick={() => accordionsRefs.current[ACCORDIONS.X_AXIS]?.scrollIntoView({ behavior: 'smooth' })}
          >
            <div
              className="settings_accordion_item"
              ref={(element: HTMLDivElement) => (dropdownsRefs.current[ACCORDIONS.X_AXIS] = element)}
            >
              <div className="settings_dropdown-group">
                <span className="settings_sub-title">{t<string>('settings.select_a_status_column')}</span>
                <CustomDropdown
                  value={selectedOption}
                  options={statusColumnOptions}
                  onChange={handleColumnChange}
                  isClearable={true}
                  onMenuOpen={() => setOpenedDropdown(ACCORDIONS.X_AXIS)}
                  onMenuClose={() => setOpenedDropdown('')}
                />
              </div>
              <div className="settings_block">
                <span className="settings_sub-title spacing">{t<string>('settings.choose_status') + ''}</span>

                <OrderedList
                  onChange={handleStatusColumnChange}
                  onOrderChange={handleStatusOrderChange}
                  items={statuses}
                />
              </div>
            </div>
          </AccordionItem>
        </Accordion>

        <Accordion
          defaultIndex={[0]}
          allowMultiple
          className="settings_accordion"
          ref={(element: HTMLDivElement) => (accordionsRefs.current[ACCORDIONS.Y_AXIS] = element)}
        >
          <AccordionItem
            title={t('settings.y_axis')}
            onClick={() => accordionsRefs.current[ACCORDIONS.Y_AXIS]?.scrollIntoView({ behavior: 'smooth' })}
          >
            <div
              className="settings_accordion_item"
              ref={(element: HTMLDivElement) => (dropdownsRefs.current[ACCORDIONS.Y_AXIS] = element)}
            >
              <div className="settings_dropdown-group">
                <span className="settings_sub-title ">{t<string>('settings.select_a_number_column')}</span>
                <CustomDropdown
                  value={selectedNumberColumnOption}
                  options={[COUNT_ITEM_OPTION, ...numberColumnOption]}
                  onChange={handleNumberColumnChange}
                  onMenuOpen={() => setOpenedDropdown(ACCORDIONS.Y_AXIS)}
                  onMenuClose={() => setOpenedDropdown('')}
                />
                <Divider direction={Divider.directions.HORIZONTAL} />
                <span className="settings_sub-title ">{t<string>('settings.calculation_func')}</span>
                <ButtonGroup
                  className="settings_group-btn"
                  disabled={!Boolean(selectedNumberColumnOption?.value)}
                  onSelect={(e: string) => setCalculationType(e)}
                  options={CALCULATION_FUNC_OPTIONS}
                  value={settingsState.calculationType}
                />
              </div>
            </div>
          </AccordionItem>
        </Accordion>

        <Accordion
          defaultIndex={[0]}
          allowMultiple
          className="settings_accordion"
          ref={(element: HTMLDivElement) => (accordionsRefs.current[ACCORDIONS.DISPLAY_OPTIONS] = element)}
        >
          <AccordionItem
            title={t('settings.display_options')}
            onClick={() => accordionsRefs.current[ACCORDIONS.DISPLAY_OPTIONS]?.scrollIntoView({ behavior: 'smooth' })}
          >
            <div
              className="settings_accordion_item"
              ref={(element: HTMLDivElement) => (dropdownsRefs.current[ACCORDIONS.DISPLAY_OPTIONS] = element)}
            >
              <div className="settings_dropdown-group">
                <span className="settings_sub-title">{t<string>('settings.choose_bars_colors')}</span>
                <CustomDropdown
                  value={selectedDisplayOption}
                  options={displayOptions}
                  onChange={handleDisplayOptionChange}
                  isDisabled={!settingsState.selectedColumn}
                  onMenuOpen={() => setOpenedDropdown(ACCORDIONS.DISPLAY_OPTIONS)}
                  onMenuClose={() => setOpenedDropdown('')}
                />
              </div>
            </div>
          </AccordionItem>
        </Accordion>

        {settingsState.boardsInfo.length > 1 && (
          <Accordion
            defaultIndex={[0]}
            allowMultiple
            className="settings_accordion"
            ref={(element: HTMLDivElement) => (accordionsRefs.current[ACCORDIONS.BOARD_SELECTION] = element)}
          >
            <AccordionItem
              title={t('settings.boards')}
              onClick={() => accordionsRefs.current[ACCORDIONS.BOARD_SELECTION]?.scrollIntoView({ behavior: 'smooth' })}
            >
              <div className="settings_accordion_item">
                <div className="settings_radio_btn-group">
                  <span className="settings_sub-title settings_radio_btn-group__subtitle">
                    {t<string>('settings.choose_board')}
                  </span>
                  {settingsState.boardsInfo.map((board, i) => (
                    <WidgetTooltip
                      key={board.id}
                      content={board.name}
                      enable={radioBtnsTooltips[i] && isBoardNameOverflowActive(radioBtnsTooltips[i])}
                    >
                      <div
                        className="radio-btn"
                        ref={(element: HTMLDivElement) => (radioBtnsRefs.current[i] = element)}
                      >
                        <RadioButton
                          text={board.name}
                          value={board.id}
                          checked={board.id === settingsState.selectedBoardId}
                          onSelect={(e: any) => handleBoardSelect(e.target.value)}
                          noLabelAnimation
                        />
                      </div>
                    </WidgetTooltip>
                  ))}
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        )}
      </div>
    </div>
  );
};

function columnToOption(column: Column | null, icon?: JSX.Element) {
  if (!column) return null;
  if (icon) {
    return {
      value: column.id,
      label: column.title,
      icon,
    };
  }

  return {
    value: column.id,
    label: column.title,
  };
}

function statusToListItem(status: StatusColumnValue): ListItem {
  return {
    id: status.id,
    checked: status.state === STATUS_COLUMN_STATE.SELECTED,
    title: status.label,
  };
}
