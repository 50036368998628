import { t } from 'i18next';

import { ReactComponent as EmptyStateImg } from '../../assets/no_filter_screen.svg';

import './EmptyState.scss';

export const EmptyState: React.FC = () => {
  return (
    <div className="empty-state">
      <div className="empty-state_container">
        <EmptyStateImg className="empty-state_img" />
        <h3 className="empty-state_title">{t<string>('empty_state.no_results_found')}</h3>
      </div>
    </div>
  );
};
