import FunnelProvider from './FunnelContext';
import SettingsProvider from './SettingsContext';
import UiProvider from './UiContext';

export const RootProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <SettingsProvider>
      <FunnelProvider>
        <UiProvider>{children}</UiProvider>
      </FunnelProvider>
    </SettingsProvider>
  );
};
