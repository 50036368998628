export enum UI_MODE {
  SETTINGS = 'settings',
  NORMAL = 'normal',
  ERROR = 'error',
}

export const DISPLAY_OPTIONS_VALUES = {
  SELECTED_COLUMN_COLOR: 'selected_column_color',
  SINGLE_COLOR: 'single_color',
  FINAL_STAGE_COLOR: 'final_stage_color',
};

export const DISPLAY_OPTIONS = [
  { value: DISPLAY_OPTIONS_VALUES.SINGLE_COLOR, label: 'Single color' },
  { value: DISPLAY_OPTIONS_VALUES.FINAL_STAGE_COLOR, label: 'Final stage only' },
];

export const CUSTOM_SYMBOL = 'custom';
export const DEFAULT_LABEL_COLOR = 'grey';
