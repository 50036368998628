import { UI_MODE } from '../constants';

type UiMode = UI_MODE.SETTINGS | UI_MODE.NORMAL;

export function getMode(): UiMode {
  const url = new URL(window.location.href);
  if (url.searchParams.get('mode') === 'settings') {
    return UI_MODE.SETTINGS;
  } else {
    return UI_MODE.NORMAL;
  }
}
