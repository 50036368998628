import { STATUS_COLUMN_STATE } from '../../constants';
import { ItemInfo } from '../../services/monday/monday-service';
import { StatusColumnInfo, StatusColumnValue } from '../../types';
import { roundNumber } from '../../utils';

export function calculateStatusesCount(items: ItemInfo[], statuses: StatusColumnValue[]) {
  return statuses.reduceRight(
    (acc, status) => {
      const statusItems = items.filter((item) => item.statusId === Number(status.id));
      const statusId = status.id;

      if (status.state === STATUS_COLUMN_STATE.SELECTED) {
        acc.statusesCount[statusId] = statusItems.length + acc.sumPrev;
        acc.sumPrev = 0;
      } else {
        acc.sumPrev += statusItems.length;
      }

      return acc;
    },
    { statusesCount: {}, sumPrev: 0 } as { statusesCount: { [key: string]: number }; sumPrev: number }
  ).statusesCount;
}

export function groupStatusesNumbers(items: ItemInfo[], statuses: StatusColumnValue[], numColumnId?: string) {
  if (!numColumnId) return {};

  const itemSettings = getItemSettings(items, numColumnId);
  return statuses.reduceRight(
    (acc, status) => {
      const statusId = status.id;
      const statusNumbers = itemSettings[statusId] || [];

      if (status.state === STATUS_COLUMN_STATE.SELECTED) {
        acc.statusesCount[statusId] = [...statusNumbers, ...acc.sumNumbers];
        acc.sumNumbers = [];
      } else {
        acc.sumNumbers = [...statusNumbers, ...acc.sumNumbers];
      }

      return acc;
    },
    { statusesCount: {}, sumNumbers: [] } as { statusesCount: { [key: string]: number[] }; sumNumbers: number[] }
  ).statusesCount;
}

export function getStatusConversion(statusColumnValues: StatusColumnInfo[]) {
  const statusConversion = statusColumnValues.reduceRight(
    (acc: { statuses: StatusColumnInfo[]; amount: number; numbers: number[] }, statusValue) => {
      acc.amount += statusValue.count;
      acc.numbers = [...statusValue.numbers, ...acc.numbers];

      acc.statuses.push({
        ...statusValue,
        count: acc.amount,
        numbers: acc.numbers,
      });

      return acc;
    },
    { statuses: [], amount: 0, numbers: [] }
  ).statuses;

  return statusConversion.reverse().map((status, i, arr) => {
    const percent = i === 0 ? 100 : roundNumber((status.count * 100) / arr[i - 1].count);

    return {
      count: status.count || 0,
      percent: percent || 0,
      id: status.id,
      label: status.label,
      numbers: status.numbers || [],
    };
  });
}

export function getSumConversion(itemsConversion: StatusConversion[]) {
  const sumConversion = itemsConversion.reduceRight(
    (acc, status) => {
      const sum = status.numbers.length ? status.numbers?.reduce((acc, num) => acc + num) : 0;

      acc.statuses.push({
        ...status,
        itemsCount: status.count || 0,
        count: sum,
      });

      return acc;
    },
    { statuses: [], amount: 0 } as { statuses: sumConversion[]; amount: number }
  ).statuses;

  return sumConversion.reverse().map((status, i, arr) => {
    const percent = i === 0 ? 100 : roundNumber((status.count * 100) / arr[i - 1].count);

    return { ...status, count: status.count || 0, percent: percent || 0 };
  });
}

export function getAverageConversion(itemsConversion: sumConversion[]) {
  return itemsConversion
    .reduceRight(
      (acc, status) => {
        acc.statuses.push({
          ...status,
          count: roundNumber(status.count / status.itemsCount || 0),
        });

        return acc;
      },
      { statuses: [], amount: 0 } as {
        statuses: StatusConversion[];
      }
    )
    .statuses.reverse();
}

export function getMedianConversion(itemsConversion: StatusConversion[]) {
  return itemsConversion
    .reduceRight(
      (acc, status) => {
        const statusNumbers = status.numbers.sort((a, b) => a - b);
        let medianValue = 0;
        const middleIndex = Math.floor(statusNumbers.length / 2);

        if (statusNumbers.length % 2 === 0) {
          medianValue = roundNumber((statusNumbers[middleIndex - 1] + statusNumbers[middleIndex]) / 2);
        } else {
          medianValue = statusNumbers[middleIndex];
        }

        acc.statuses.push({
          ...status,
          count: medianValue || 0,
        });

        return acc;
      },
      { statuses: [], amount: [] } as {
        statuses: StatusConversion[];
      }
    )
    .statuses.reverse();
}

function getItemSettings(items: ItemInfo[], numColumnId: string) {
  return items?.reduce((acc, item) => {
    acc[item.statusId] = acc[item.statusId]
      ? [...acc[item.statusId], item.numberesById[numColumnId]]
      : [item.numberesById[numColumnId]];

    return acc;
  }, {} as { [key: string]: number[] });
}

export type StatusConversion = {
  id: string;
  count: number;
  percent: number;
  label: string;
  numbers: number[];
};

export interface sumConversion extends StatusConversion {
  itemsCount: number;
}
