import { Checkbox, Icon } from 'monday-ui-react-core';
import { Drag } from 'monday-ui-react-core/dist/allIcons';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { WidgetTooltip } from '../WidgetTooltip/WidgetTooltip';

import './OrderedList.scss';

export type ListItem = {
  id: string;
  title: string;
  checked: boolean;
};

export const OrderedList: React.FC<{
  items: ListItem[];
  onChange: ({ id, checked }: { id: string; checked: boolean }) => void;
  onOrderChange: (items: ListItem[]) => void;
}> = ({ items, onChange, onOrderChange }) => {
  const itemsRefs = useRef<any>([]);
  const [radioBtnsTooltips, setRadioBtnsTooltips] = useState([]);

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    onOrderChange(newItems);
  }

  useEffect(() => {
    setRadioBtnsTooltips(itemsRefs.current);
  }, [itemsRefs]);

  function isLabelOverflowActive(event: any) {
    const span = event.firstChild.lastChild;
    return span.offsetWidth < span.scrollWidth;
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="ordered-list">
          {(provided) => (
            <div className="ordered-list" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map(({ id, title, checked }, index: number) => {
                return (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                      <div className="ordered-list_item" ref={provided.innerRef} {...provided.draggableProps}>
                        <WidgetTooltip
                          content={title}
                          enable={radioBtnsTooltips[index] && isLabelOverflowActive(radioBtnsTooltips[index])}
                        >
                          <div
                            ref={(element: HTMLDivElement) => (itemsRefs.current[index] = element)}
                            className="ordered-list_item-wrapper"
                          >
                            <Checkbox
                              label={title}
                              onChange={() => onChange({ id, checked: !checked })}
                              checked={checked}
                              id={id}
                            />
                          </div>
                        </WidgetTooltip>
                        <div {...provided.dragHandleProps}>
                          <Icon
                            className="ordered-list_dnd-btn"
                            iconType={Icon.type.SVG}
                            icon={Drag}
                            ignoreFocusStyle
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
