import { FRAMES_CONSTANTS } from '../constants';
import { FunnelState } from '../contexts/FunnelContext';
import { SettingsState } from '../contexts/SettingsContext';
import { storage } from './storage';

export function setFrameProperty(key: string, value: string) {
  // @ts-ignore
  window.frames[key] = value;
}

export async function initSettings() {
  let resolves: any;
  const promise = new Promise((res) => {
    resolves = res;
  });

  const listener = (event: any) => {
    if (event.origin !== window.origin) return;
    if (event.data?.isState) {
      resolves(event.data.state);
    }
  };

  window.frames.addEventListener('message', listener);
  const frameId = await storage.getFromMonday(FRAMES_CONSTANTS.FRAME_ID);
  setFrameProperty(FRAMES_CONSTANTS.FRAME_ID, frameId);

  const mainApp = findMainApp(frameId);
  mainApp?.postMessage('SEND_STATE', mainApp.origin);

  const state = (await promise) as { funnel: FunnelState; settings: SettingsState };
  return state;
}

export function findMainApp(id?: string) {
  if (!id) {
    id = window[FRAMES_CONSTANTS.FRAME_ID];
  }

  const frames = window.parent.frames;
  for (let i = 0; i < frames.length; i++) {
    if (isMainAppFrame(frames[i], id!)) return frames[i];
  }
}

export function isMainAppFrame(frame: any, id: string) {
  try {
    return (
      frame.origin === window.origin &&
      frame[FRAMES_CONSTANTS.MODE] === FRAMES_CONSTANTS.NORMAL &&
      frame[FRAMES_CONSTANTS.FRAME_ID] === id
    );
  } catch {
    return false;
  }
}

export function findSettingsApp() {
  const frames = window.parent.frames;
  for (let i = 0; i < frames.length; i++) {
    if (isSettingsAppFrame(frames[i])) return frames[i];
  }
}

export function isSettingsAppFrame(frame: any) {
  try {
    return (
      frame.origin === window.origin &&
      frame[FRAMES_CONSTANTS.MODE] === FRAMES_CONSTANTS.SETTINGS &&
      window[FRAMES_CONSTANTS.FRAME_ID] === frame[FRAMES_CONSTANTS.FRAME_ID]
    );
  } catch {
    return false;
  }
}
