import camelcaseKeys from 'camelcase-keys';

import { CUSTOM_SYMBOL } from '../constants';

export function toCamelCase(response: any) {
  return camelcaseKeys(response, { deep: true });
}

export function formatNumeric(value: number, settingsStr?: string, original = false) {
  const formatedValue = formatNumber(value, original);
  if (!settingsStr) return formatedValue;
  const settings = JSON.parse(settingsStr);
  if (!settings?.unit) return formatedValue;
  const { custom_unit, direction, symbol } = settings.unit;

  const selectedSymbol = symbol === CUSTOM_SYMBOL ? custom_unit : symbol;
  return direction === 'right' ? formatedValue + selectedSymbol : selectedSymbol + formatedValue;
}

export function formatNumber(amount: number, original: boolean) {
  let formatedNumb = new Intl.NumberFormat('en-GB').format(amount);
  if (original) return formatedNumb;

  if (amount >= 1e4) {
    formatedNumb = (amount / 1e3).toFixed(2) + 'K';
  }
  if (amount >= 1e6) {
    formatedNumb = (amount / 1e6).toFixed(2) + 'M';
  }
  if (amount >= 1e9) {
    formatedNumb = (amount / 1e9).toFixed(2) + 'B';
  }
  return formatedNumb;
}
